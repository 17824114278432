import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callCommonAction } from "../../../redux/Common/CommonReducer";
import { sendRequest } from "../../../apis/APIs";
import { toast } from "react-toastify";
import { CardElement, Elements, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../Register/CheckoutForm";
import Alerts from "../../../common/Alerts/Alerts";
import { handleApiResponse } from "../../../apis/apiUtils";
import { Helmet } from "react-helmet";


const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

const Setting = () => {
  const checkoutFormRef = useRef(null);
  const { alert } = useSelector((state) => state.common);
  const [cardError, setCardError] = useState(null);
  const [cards, setCards] = useState([]);
  const dispatch = useDispatch();

  const addCard = async () => {
    try {
      if (cards.length >= 6) {
        toast.error("You can only add up to four cards. Please delete one to add a new card.");
        return;
      }

      dispatch(callCommonAction({ loading: true }));
      const paymentElement = await checkoutFormRef.current.handleCardElement();

      if (paymentElement.type !== 'validation_error') {
        if (paymentElement.type !== 'error') {
          const isDuplicate = cards.some(card => {
            const isSameLast4 = card.last4 === paymentElement.card.last4;
            const isSameBrand = card.brand === paymentElement.card.brand;
            return isSameLast4 && isSameBrand;
          });

          if (isDuplicate) {
            toast.error("This card has already been added.");
            dispatch(callCommonAction({ loading: false }));
            return;
          }

          const res = await sendRequest(`/payment/save-card`, "POST", paymentElement);

          handleApiResponse(res);
          if (res.data.type == "success") {
            localStorage.setItem('user', JSON.stringify(res.data.user));
            dispatch(callCommonAction({ user: res.data.user }));
            // Reset CheckoutForm state (assuming a resetForm function exists)
            if (checkoutFormRef.current) {
              checkoutFormRef.current.resetForm();
            }
            getUserCards();
          }
        }

      }
      dispatch(callCommonAction({ loading: false }));

    } catch (error) {
      console.error("Error adding card:", error);
      dispatch(callCommonAction({ loading: false }));
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    getUserCards();
  }, [setCards]);

  const getUserCards = async () => {
    try {
      dispatch(callCommonAction({ loading: true, breadCrumb: ['Card', 'List'] }));
      const res = await sendRequest(`/payment/card-list`, "GET", {});
      setCards(res.data.data);
      dispatch(callCommonAction({ loading: false }));
    } catch (error) {
      console.log("error", error);
      toast.error("Something went wrong");
      dispatch(callCommonAction({ loading: false }));
    }
  };


  /********Before Delete*******/
  const confirmBeforeDelete = (card) => {
    const custom = Alerts.confirmDelete(card, deleteSelectedCard);
    dispatch(callCommonAction({ alert: custom }));
  };
  const deleteSelectedCard = async (card) => {
    try {
      dispatch(callCommonAction({ alert: null }));
      const res = await sendRequest(`/payment/delete-card`, "POST", {
        card: card,
      });
      const updatedCards = cards.filter((card) => card.id !== card); // Corrected comparison
      setCards(updatedCards);
      handleApiResponse(res);
      getUserCards();
    } catch (error) {
      console.error("Error deleting card:", error);
      toast.error("Failed to delete card");
    }
  };
  const confirmBeforeStatus = (card) => {
    const custom = Alerts.confirmUpdate(card, updateSelectedCard);
    dispatch(callCommonAction({ alert: custom }));
  };

  const updateSelectedCard = async (card) => {
    try {
      dispatch(callCommonAction({ alert: null }));
      const changeStatus = await sendRequest(`/payment/change-status`, "POST", {
        card: card,
      });
      getUserCards();
      handleApiResponse(changeStatus);
    } catch (error) {
      console.error("Error updating card:", error);
      toast.error("Failed to update card");
    }
  };

  const handleApiFormSubmit = async (e) => {
    e.preventDefault();
    // You can add form submission logic here if needed
  };


  return (
    <>
      <Helmet>
        <title>leadBoost | Card Management</title>
        <meta name="description" content="Description goes here" />
        <meta name="keywords" content="Game, Entertainment, Movies" />
      </Helmet>
      {alert}
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-xxl">
            <div className="card">
              <div className="card-header">
                <div className="card-title">
                  <h4>Add new card</h4>
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={handleApiFormSubmit}>
                  <div className="row">

                    <div className="col-md-6 ps-md-5">
                      <Elements stripe={stripePromise}>
                        <CheckoutForm ref={checkoutFormRef} />
                      </Elements>

                      {cardError && (
                        <div className="text-danger">{cardError}</div>
                      )}
                      <div className="col align-items-start d-flex flex-column pt-5">
                        <button
                          type="button"
                          className="btn btn-primary  "
                          onClick={addCard}
                          style={{ padding: "5px 7px", fontSize: "13px" }}
                        ><i className="ki-duotone ki-plus fs-2"></i>
                          Add Card
                        </button>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="post d-flex flex-column-fluid" id="kt_xcard_list">
          <div id="kt_content_container" className="container-xxl">
            <div className="card" id="kt_pricing">
              <div className="card-header">
                <div className="card-title">
                  <h4>My Cards</h4>
                </div>
              </div>
              <div>
                <div
                  id="kt_billing_payment_tab_content"
                  className="card-body tab-content"
                >
                  <div
                    id="kt_billing_creditcard"
                    className="tab-pane fade show active"
                    role="tabpanel"
                  >
                    <div className="row gx-9 gy-6">
                      {/* {console.log(cards.length)} */}
                      {
                        cards && cards.length
                          ?
                          cards
                            .sort((a, b) => {
                              if (a.is_default === 1 && b.is_default !== 1) {
                                return -1; // a should come before b
                              } else if (a.is_default !== 1 && b.is_default === 1) {
                                return 1; // b should come before a
                              } else {
                                return 0; // maintain the existing order
                              }
                            })
                            .map((card) => (
                              <div
                                className="col-xl-6"
                                data-kt-billing-element="card"
                                key={card.id}
                              >
                                <div className={`card ${card.is_default === 1 ? 'border border-primary' : 'card-dashed'} h-xl-100 flex-row flex-stack flex-wrap p-6`}>
                                  <div className="d-flex flex-column py-2">
                                    <div className="d-flex align-items-center fs-4 fw-bold mb-5">
                                      {card.owner}{" "}
                                      {
                                        card.is_default
                                          ?
                                          <span className={`badge badge-light-success fs-7 ms-2`} >
                                            Primary
                                          </span>
                                          :
                                          null
                                      }
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <img
                                        src={`assets/media/svg/card-logos/${card.brand.toLowerCase()}.svg`}
                                        alt=""
                                        className="me-4"
                                      />
                                      <div>
                                        <div className="fs-4 fw-bold">
                                          {card.brand} **** {card.last4}
                                        </div>
                                        <div className="fs-6 fw-semibold text-gray-500">
                                          Card expires at {card.exp_month}/
                                          {card.exp_year}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center py-2">
                                    {card.is_default !== 1 && (
                                      <button
                                        className="btn btn-sm btn-light btn-active-light-primary me-1"
                                        data-kt-billing-action="card-delete"
                                        onClick={() => confirmBeforeDelete(card)} // Add onClick event handler
                                      >
                                        <span className="indicator-label">
                                          Delete
                                        </span>
                                        <span className="indicator-progress">
                                          Please wait...{" "}
                                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                      </button>
                                    )}
                                  </div>
                                  <div className="d-flex align-items-center py-2">
                                    {card.is_default !== 1 && (
                                      <button
                                        className="btn btn-sm btn-light btn-active-light-primary me-1"
                                        data-kt-billing-action="card-delete"
                                      >
                                        <span
                                          className="indicator-label"
                                          onClick={() => confirmBeforeStatus(card)}
                                        >
                                          Set Default
                                        </span>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))
                          :
                          null
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;
