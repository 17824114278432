import React, { useEffect } from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
// import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Pagination = props => {
	
	const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, className } = props;
	// console.log('asx',{ onPageChange, totalCount,  currentPage, pageSize, className } )
	const paginationRange = usePagination({ currentPage, totalCount, siblingCount, pageSize });

	console.log('paginationRange',paginationRange)
	if (paginationRange === undefined) {
		return null;
	}

	if (currentPage === 0 || paginationRange.length < 2) {
		return null;
	}


	const onNext = () => {
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		onPageChange(currentPage - 1);
	};


	let lastPage = paginationRange[paginationRange.length - 1];

	return (
		<ul className='pagination justify-content-end mt-3' >
			{currentPage === 1 ?
				<li className='page-item disabled'>
					<a className="page-link">{'Prev'}</a>
				</li>
				:
				<li className='page-item' onClick={onPrevious}>
					<a className="page-link">{'Prev'}</a>
				</li>
			}

			{paginationRange.map(pageNumber => {
				if (pageNumber === DOTS) {
					return <li className="page-item dots" key={pageNumber}><a className="page-link">&#8230;</a></li>;
				}
				return (
					<li className={classnames('page-item', { active: pageNumber === currentPage })} onClick={() => onPageChange(pageNumber)} key={pageNumber}>
						<a className="page-link cursor-pointer">{pageNumber}</a>
					</li>
				);
			})}

			{currentPage == lastPage ?
				<li className='page-item disabled'>
					<a className="page-link">{'Next'}</a>
				</li>
				:
				<li className='page-item' onClick={onNext}>
					<a className="page-link">{'Next'}</a>
				</li>
			}
		</ul>
	);
};

export default Pagination;