//import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const SetUpVideo = (props) => {
    
    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <div className='col-12'>
                    <h5 className='mb-5'>Need help finding your Business Email? Here's a quick video to show you where to find it in your account</h5>
                    <iframe className='api-video mb-0' src="https://www.youtube.com/embed/Cn2BICP7iyE?si=qaQpHALKLHFLg8_7" frameborder="0" allowfullscreen></iframe>
                    <hr></hr>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SetUpVideo;
