import React from 'react';
import appRoutes from '../configs/AppRoutes';
import { Routes, Route } from 'react-router-dom';

import ForgotPassword from './../components/ForgotPassword/ForgotPassword';
import Login from './../components/Login/Login';
import NoRouteFound from './../components/NoRouteFound';
import ResetPassword from './../components/ResetPassword/ResetPassword';
import Dashboard from './../components/Admin/Dashboard/Dashboard';
import UserList from './../components/User/List';
import Overview from '../components/Profile/Overview';
import UserDetail from './../components/User/Detail';
import PaymentHistory from '../components/Admin/Payment/List';
import ContactUs from '../components/CMS/ContactUs';
import AboutUs from '../components/CMS/AboutUs';
import Home from './../components/Home/Home';
import Credit from '../components/Admin/CreditSetting/Credit';
import PaymentList from '../components/Admin/Payment/List';
import Voucher from '../components/Voucher/Manage';
import ErrorReport from '../components/ErrorReport/List';
import PageVideo from '../components/CMS/PageVideo';

// Containers
const DefaultLayout = React.lazy(() => import('./../layout/DefaultLayout'));
const AuthLayout = React.lazy(() => import('./../layout/AuthLayout'));
const CmsLayout = React.lazy(() => import('./../layout/CmsLayout'));

const AdminRoute = () => {
    return (
        <Routes>
            {/* LoggedIn Routes Start */}
            <Route exact path="/admin/" element={<AuthLayout />} >
                <Route index element={<Dashboard />} />
                <Route path={appRoutes.adminUserDetailRoute+ "/:userId"} element={<UserDetail />} />
                <Route path={appRoutes.adminPaymentHistoryRoute} element={<PaymentHistory />} />
                <Route path={appRoutes.adminProfileRoute} element={<Overview />} />
                <Route path={appRoutes.adminDashboardRoute} element={<Dashboard />} />
                <Route path={appRoutes.adminPaymentHistoryRoute+ "/:userId"} element={<PaymentHistory />} />
                <Route path={appRoutes.adminUserListRoute} element={<UserList />} />
                <Route path={appRoutes.adminCreditRoute} element={<Credit />} />
                <Route path={appRoutes.adminPaymentListRoute} element={<PaymentList />} />
                <Route path={appRoutes.adminErrorReportRoute} element={<ErrorReport />} />
                <Route path={appRoutes.adminPageVideoRoute} element={<PageVideo />} />
                <Route path={appRoutes.adminVoucherRoute} element={<Voucher />} />
                
            </Route>
            {/* LoggedIn Routes End */}

            {/* Without Logged In Routes Start */}
            <Route exact path="/admin/" element={<DefaultLayout />} >
                <Route exact path={appRoutes.adminLoginRoute} element={<Login />} />
                <Route exact path={appRoutes.adminForgotPasswordRoute} element={<ForgotPassword />} />
                <Route exact path={appRoutes.adminResetPasswordRoute + "/:token"} element={<ResetPassword />} />
            </Route>
            {/* Without Logged In Routes Start */}


            <Route exact path="/" element={<CmsLayout />} >
                <Route exact index path={appRoutes.homePageRoute} element={<Home />} />
                <Route exact path={appRoutes.contactUsRoute} element={<ContactUs />} />
                <Route exact path={appRoutes.aboutUsRoute} element={<AboutUs />} />
            </Route>

            <Route path='*' element={<NoRouteFound />} />


        </Routes>

    );
};

export default AdminRoute;
