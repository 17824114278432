import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendRequest } from "../../apis/APIs";
import { isValidPhoneNumber } from "react-phone-number-input";
import Input from "react-phone-number-input/input";
import { callCommonAction } from "../../redux/Common/CommonReducer";
import { validateUserFormData } from "./Validation";
import "react-toastify/dist/ReactToastify.css"; // import first
import { toast } from "react-toastify";
import { handleApiResponse } from "../../apis/apiUtils";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import appSettings from "../../configs/AppConfig";
import Dropdown from "react-bootstrap/Dropdown";
import appRoutes from "../../configs/AppRoutes";
import Pagination from "../../common/Pagination/pagination";
import { Helmet } from "react-helmet";
import CryptoJS from "crypto-js";

const List = () => {
  const [users, setUsers] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const { loading2, isAdmin, user } = useSelector((state) => state.common);
  const handleClose = () => setShowEditModal(false);
  const navigate = useNavigate();
  const [errorsInfo, setErrorsInfo] = useState({
  });
  const dispatch = useDispatch();
  const [editUser, setEditUser] = useState({  first_name: "", last_name: "", email: "", phonenumber: "", password: "" ,credits: "", });
  const [paginationData, setPaginationData] = useState({ perpage: appSettings.recordsPerPageTable, page: 1, totalCount: 0 });
  const [filter, setFilter] = useState({ search_string: "", status: "all" });

  /** Applied Filter Here */
  const applyFilter = () => {
    setPaginationData({ ...paginationData, page: 1 });
    setUsers([]);
    getUsers(1, filter.status);
  };

  /**********ONLY LOAD TIME RUN***************/
  useEffect(() => {
    if (
      (isAdmin && filter.search_string.length > 2) ||
      filter.search_string === ""
    ) {
      getUsers(1, filter.status);
    }
  }, [filter.search_string]);

  const clearFilter = () => {
    setPaginationData({ ...paginationData, page: 1 });
    setFilter({ search_string: "", status: "all" });
    getUsers(1, "all");
  };

  /** Get List Of Users */
  const getUsers = async (page, status) => {
    try {
      dispatch(
        callCommonAction({ loading: true, breadCrumb: ["Agent", "List"] })
      );
      const res = await sendRequest(`/admin/user-list`, "GET", {
        page: page,
        limit: paginationData.perpage,
        search_string: filter.search_string,
        status: status,
      });
      dispatch(callCommonAction({ loading: false }));
      handleApiResponse(res);
      if (res.data.type === "success") {
        setUsers(res.data.data.docs);
        setPaginationData({
          ...paginationData,
          totalCount: res.data.data.totalDocs,
          page: res.data.data.page,
        });
      }
    } catch (error) {
      toast.error("Something went wrong");
      dispatch(callCommonAction({ loading: false }));
    }
  };

  const userSwitch = async (userObj, decrypt) => {
    try {
      dispatch(callCommonAction({ loading: true, isLoggedIn: false }));
      let reqUrl = decrypt ? "/admin/switch" : `/user/switch`;
      const res = await sendRequest(reqUrl, "POST", userObj);
      dispatch(callCommonAction({ loading: false }));
      handleApiResponse(res);
      if (res.data.type === "success") {
        localStorage.setItem("token", res.data.access_token);
        delete res.data.data.password;
        localStorage.setItem("user", JSON.stringify(res.data.data));
        localStorage.setItem("isAdmin", decrypt);
        const jsonString = JSON.stringify(user);
        const encrypted = CryptoJS.AES.encrypt(
          jsonString,
          process.env.REACT_APP_CRYPTO_SECRET_KEY
        ).toString();
        localStorage.setItem("398de222-5bf9-4754-8e3e-011a55307014", encrypted);
        dispatch(
          callCommonAction({
            isLoggedIn: true,
            user: res.data.data,
            token: res.data.access_token,
            isAdmin: decrypt,
          })
        );
        decrypt
          ? navigate(appRoutes.adminDashboardRoute)
          : navigate(appRoutes.dashboardRoute);
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(callCommonAction({ loading: false }));
    }
  };

  const renderTabeBody = () => {
    return users.map((user) => {
      return (
        <tr key={user.id}>
          <td className="d-flex align-items-center">
            {/**begin:: Avatar **/}
            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
              <a href={undefined}>
                <div className="symbol-label">
                  <img
                    src={
                      user && user.user_image
                        ? process.env.REACT_APP_ASSET_ENDPOINT +
                        "/" +
                        user.user_image
                        : "/assets/media/avatars/blank.png"
                    }
                    alt="Emma Smith"
                    className="w-100"
                  />
                </div>
              </a>
            </div>
            {/**end::Avatar**/}
            {/**begin::User details**/}
            <div className="d-flex flex-column">
              <a
                href={undefined}
                className="text-gray-800 text-hover-primary mb-1"
                onClick={() =>
                  navigate(appRoutes.adminUserDetailRoute + "/" + user._id)
                }
              >
                {user?.first_name +" "+ user?.last_name}
              </a>
              <span>{user.email}</span>
            </div>
            {/**begin::User details**/}
          </td>
          <td className="text-center">${user.total_payments}</td>

          <td className="text-center">{user.number_of_full_contact_req?? 0}</td>
          <td >{user.credits.toFixed(2)}</td>

          {/* <td className='text-center'><span className={`text-uppercase badge badge-light-${badgeCls}`}>{user.status_name}</span></td> */}
          <td>
            <div className={"text-center"}>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                defaultChecked={user.status === 1}
                onChange={(e) => handleChangeStatus(e, user._id)}
              />
            </div>
          </td>
          <td className="text-end">
            <Dropdown>
              <Dropdown.Toggle
                className="btn btn-light btn-active-light-primary btn-sm"
                id="dropdown-basic"
              >
                Actions
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  href={undefined}
                  onClick={() =>
                    navigate(appRoutes.adminUserDetailRoute + "/" + user._id)
                  }
                >
                  View
                </Dropdown.Item>
                <Dropdown.Item
                  href={undefined}
                  onClick={() => handleEdit(user)}
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item
                  href={undefined}
                  onClick={() => userSwitch(user, false)}
                >
                  Login as Agent
                </Dropdown.Item>

                <Dropdown.Item
                  href={undefined}
                  onClick={() => navigate(appRoutes.adminPaymentHistoryRoute + "/" + user._id)}
                >
                  Payment History
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      );
    });
  };

  /** Edit User **/
  const handleEdit = (user) => {
    setEditUser(user);
    setShowEditModal(true);
  };

  /** handlePhoneChange */
  const handlePhoneChange = (phonenumber) => {
    //console.log('phone change', phonenumber);
      editUser.phonenumber = phonenumber === undefined ? "" : phonenumber ;
      setEditUser(editUser);
      checkFormIsValid("phonenumber");
  };
  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setEditUser({ ...editUser, password });
    checkFormIsValid("password");
  };
  
  const handleConfirmPasswordChange = (e) => {
    const confirmPassword = e.target.value;
    setEditUser({ ...editUser, confirmPassword });
    if (confirmPassword !== editUser.password) {
      setErrorsInfo({
        ...errorsInfo,
        confirmPassword: "Passwords do not match",
      });
    } else {
      setErrorsInfo({ ...errorsInfo, confirmPassword: "" });
    }
  };
  const handleCreditChange = (e) => {
    const credits = e.target.value;
    if (credits < 2) {
      setEditUser({ ...editUser, credits });
      setErrorsInfo({ ...errorsInfo, credits: 'Credits cannot be less than 0' });
    } else {
      setEditUser({ ...editUser, credits });
      checkFormIsValid("credits");
    }
  };
  
  

  const handleChangeStatus = async (event, userId) => {
    try {
      let newStatus = event.target.checked ? 1 : 0;
      dispatch(callCommonAction({ loading: true }));
      const res = await sendRequest("/admin/update-user-status", "POST", {
        status: newStatus,
        user_id: userId,
      });
      dispatch(callCommonAction({ loading: false }));
      handleApiResponse(res);
    } catch (error) {
      //console.log('asxsa', error);
      toast.error("Something went wrong");
      dispatch(callCommonAction({ loading: false }));
    }
  };

  /** OnChange Update Input Values */
  const handleChange = (e) => {
    //console.log('e', e)
    editUser[e.target.name] = e.target.value;
    setEditUser(editUser);
    checkFormIsValid(e.target.name);
  };

  /** Validation implemented here */
  const checkFormIsValid = async (fieldName) => {
    const res = validateUserFormData(editUser, fieldName, isValidPhoneNumber);
    if (fieldName) {
      fieldName = fieldName.trim();
      let err = res.errors[fieldName];
      if (typeof err != "undefined") {
        errorsInfo[fieldName] = err;
      } else {
        errorsInfo[fieldName] = "";
      }
      setErrorsInfo({ ...errorsInfo });
    } else {
      setErrorsInfo({ ...res.errors });
    }
    return res.formVaildCheck;
  };

  /** Update User From Here **/
  const submitUpdateForm = async (e) => {
    if (await checkFormIsValid("")) {
      try {
        dispatch(callCommonAction({ loading2: true }));
        const { first_name, last_name, email, phonenumber, _id, password ,credits} =  editUser;
        const res = await sendRequest(`/admin/update-user-profile/${_id}`, "POST", {  first_name, last_name, email, phonenumber, password,credits }
        );
        dispatch(callCommonAction({ loading2: false }));
        handleApiResponse(res);
        if (res.data.type === "success") {
          setShowEditModal(false);
          setEditUser(null);
          getUsers(paginationData.page, filter.status);
        }
      } catch (error) {
        toast.error("Something went wrong");
        dispatch(callCommonAction({ loading: false }));
      }
    }
  };

  /** Edit User Form ***/
  const userUpdateForm = () => {
    return (
      <Modal show={showEditModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Agent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              {/* First Name Field */}
              <div className="col-md-6 mb-3">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>First name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter first name"
                    value={editUser.first_name}
                    name="first_name"
                    className={`form-control bg-transparent ${errorsInfo.first_name ? "is-invalid" : ""
                      }`}
                    onChange={handleChange}
                    autoFocus
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{errorsInfo.first_name}</span>
                    </div>
                  </div>
                </Form.Group>
              </div>
              {/* Last Name Field */}
              <div className="col-md-6 mb-3">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Last name</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter last name"
                    value={editUser.last_name}
                    name="last_name"
                    className={`form-control bg-transparent ${errorsInfo.last_name ? "is-invalid" : ""
                      }`}
                    onChange={handleChange}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{errorsInfo.last_name}</span>
                    </div>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              {/* Phone Number Field */}
              <div className="col-md-6 mb-3">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Phone Number</Form.Label>
                  <Input
                    country="US"
                    placeholder="Phone Number"
                    className={`form-control bg-transparent ${errorsInfo.phonenumber ? "is-invalid" : ""
                      }`}
                    onChange={(e) => handlePhoneChange(e)}
                    value={editUser.phonenumber}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{errorsInfo.phonenumber}</span>
                    </div>
                  </div>
                </Form.Group>
              </div>
              {/* Email Field */}
              <div className="col-md-6 mb-3">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    name="email"
                    value={editUser.email}
                    className={`form-control bg-transparent ${errorsInfo.email ? "is-invalid" : ""
                      }`}
                    onChange={handleChange}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{errorsInfo.email}</span>
                    </div>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Password</Form.Label>
                  <input
                    type="password"
                    placeholder="Password"
                    className={`form-control bg-transparent ${errorsInfo.password ? "is-invalid" : ""
                      }`}
                    onChange={handlePasswordChange}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block"></div>
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-6 mb-3">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Confirm Password</Form.Label>
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    className={`form-control bg-transparent ${errorsInfo.confirmPassword ? "is-invalid" : ""
                      }`}
                    onChange={handleConfirmPasswordChange}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{errorsInfo.confirmPassword}</span>
                    </div>
                  </div>
                </Form.Group>
              </div>
            </div>


            <div className="row">
              <div className="col-md-6 mb-3">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Credits</Form.Label>
                  <input
                    type="credits"
                    placeholder="credits"
                    value={editUser.credits}

                    className={`form-control bg-transparent ${errorsInfo.password ? "is-invalid" : ""
                      }`}
                    onChange={handleCreditChange}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block"></div>
                  </div>
                </Form.Group>
              </div>
             
            </div>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={submitUpdateForm}
            disabled={loading2 ? true : false}
          >
            {loading2 ? (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            ) : (
              <span className="indicator-label">Save Changes</span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };


  return (
    <div
      className="content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Helmet>
        <title>leadBoost | Agent Management</title>
        <meta name="description" content="Description goes here" />
        <meta name="keywords" content="Game, Entertainment, Movies" />
      </Helmet>
      {/**begin::Post**/}
      <div className="post d-flex flex-column-fluid" id="kt_post">
        {/**begin::Container**/}
        <div id="kt_content_container" className="container-xxl">
          {/**begin::Card**/}
          <div className="card">
            {/**begin::Card header**/}
            <div className="card-header border-0 pt-6">
              {/**begin::Card title**/}
              <div className="card-title w-100 w-md-auto">
                {/**begin::Search**/}
                <div className="align-items-center d-flex my-1 position-relative w-100">
                  <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                  <input
                    type="text"
                    data-kt-user-table-filter="search"
                    value={filter.search_string}
                    onChange={(e) =>
                      setFilter({ ...filter, search_string: e.target.value })
                    }
                    className="form-control form-control-solid ps-13 border w-100"
                    placeholder="Search user"
                  />
                </div>
                {/**end::Search**/}
              </div>
              {/**begin::Card title**/}
              {/**begin::Card toolbar**/}
              <div className="card-toolbar w-100 w-md-auto">
                <div className="align-items-center d-flex flex-md-nowrap flex-wrap w-100">
                  <div className="align-items-center d-flex mb-3 mb-md-0 me-0 me-md-2 w-100">
                    <label className="form-label fw-semibold mb-0 me-2">
                      Status:
                    </label>
                    <div className="w-100">
                      <select
                        className="form-select form-select-solid fw-bold"
                        value={filter.status}
                        onChange={(e) => {
                          setFilter({ ...filter, status: e.target.value });
                        }}
                      >
                        <option value="all">All</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end w-100">
                    <button
                      type="reset"
                      className="btn btn-light btn-active-light-primary me-2"
                      onClick={clearFilter}
                    >
                      Reset
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={applyFilter}
                    >
                      Apply
                    </button>
                  </div>
                </div>

                {/**end::Toolbar**/}
                {/**begin::Group actions**/}
                <div
                  className="d-flex justify-content-end align-items-center d-none"
                  data-kt-user-table-toolbar="selected"
                >
                  <div className="fw-bold me-5">
                    <span
                      className="me-2"
                      data-kt-user-table-select="selected_count"
                    ></span>
                    Selected
                  </div>
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-kt-user-table-select="delete_selected"
                  >
                    Delete Selected
                  </button>
                </div>
                {/**end::Group actions**/}
              </div>
              {/**end::Card toolbar**/}
            </div>
            {/**end::Card header**/}
            {/**begin::Card body**/}
            <div className="card-body table-frame py-4 border-top mt-5">
              {/**begin::Table**/}
              <div className="table-responsive">
                <table
                  className="table align-middle table-row-dashed fs-6 gy-5"
                  id="kt_table_users"
                >
                  <thead>
                    <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                      <th className="min-w-125px">Agent</th>
                      <th className="min-w-125px">Amount Spent</th>
                      <th className="min-w-125px">No. of Enrichment Request</th>
                      <th className="min-w-125px">Credits</th>

                      <th className="min-w-125px text-center">Status</th>
                      <th className="text-end min-w-100px">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-600 fw-semibold">
                    {users.length > 0 ? (
                      renderTabeBody()
                    ) : (
                      <tr>
                        <td colSpan={4} className="text-center">
                          No result found!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/**end::Table**/}
              <Pagination
                className="pagination-bar"
                currentPage={paginationData.page}
                totalCount={paginationData.totalCount}
                pageSize={paginationData.perpage}
                onPageChange={(page) => getUsers(page, filter.status)}
              />
              {showEditModal ? userUpdateForm() : null}
            </div>
            {/**end::Card body**/}
          </div>
          {/**end::Card**/}
        </div>
        {/**end::Container**/}
      </div>
      {/**end::Post**/}
    </div>
  );
};

export default List;
