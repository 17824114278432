import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";
import Input from "react-phone-number-input/input";
import { sendRequest } from "../../apis/APIs";
import { callCommonAction } from "../../redux/Common/CommonReducer";
import { useNavigate, Link } from "react-router-dom";
import appRoutes from "../../configs/AppRoutes";
import { validateRegisterFormData } from "./Validation";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import "react-toastify/dist/ReactToastify.css"; // import first
import { toast } from "react-toastify"; // then this
import { handleApiResponse } from "../../apis/apiUtils";
import { Helmet } from "react-helmet";
import { GoogleLogin } from '@react-oauth/google';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

const Register = () => {
  const checkoutFormRef = useRef();
  const navigate = useNavigate();
  const [formStep, setFormStep] = useState(1);
  const [userDetail, setUserDetail] = useState({
    terms: false,
    phonenumber: "",
    role: 2,
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    amount: 0,
    credits: 0,
    minimum_credits: 0,
    average_price: 0,
    credit_detail: null,
  });
  const [errorsInfo, setErrorsInfo] = useState({});
  const { loading, loading2 } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    getRegisterFormData();
  }, []);

  const getRegisterFormData = async () => {
    try {
      dispatch(callCommonAction({ loading: true }));
      const res = await sendRequest("/admin/get-credit", "GET", {});
      dispatch(callCommonAction({ loading: false }));
      if (res.data.type === "success") {
        let amt =
          res.data.data.default_purchase_credit * res.data.data.average_price;
        setUserDetail({
          ...userDetail,
          minimum_credits: res.data.data.default_purchase_credit,
          credits: res.data.data.default_purchase_credit,
          amount: amt,
          average_price: res.data.data.average_price,
          credit_detail: res.data.data
        });
      }
    } catch (error) {
      toast.error("Something went wrong");
      dispatch(callCommonAction({ loading: false }));
    }
  };



  /** OnChange Update Input Values */
  const handleChange = (e) => {
    userDetail[e.target.name] = e.target.value;
    setUserDetail(userDetail);
    checkFormIsValid(e.target.name);
  };

  const handleTermsChange = (e) => {
    userDetail["terms"] = e.target.checked;
    setUserDetail(userDetail);
    checkFormIsValid(e.target.name);
  };

  /** handlePhoneChange */
  const handlePhoneChange = (phonenumber) => {
    if (phonenumber) {
      userDetail.phonenumber = phonenumber;
      setUserDetail(userDetail);
      checkFormIsValid("phonenumber");
    }
  };

  /** Validation implemented here */
  const checkFormIsValid = async (fieldName) => {
    const res = validateRegisterFormData(formStep, userDetail, fieldName, isValidPhoneNumber);
    if (fieldName) {
      fieldName = fieldName.trim();
      let err = res.errors[fieldName];
      if (typeof err != "undefined") {
        errorsInfo[fieldName] = err;
      } else {
        errorsInfo[fieldName] = "";
      }
      setErrorsInfo({ ...errorsInfo });
    } else {
      setErrorsInfo({ ...res.errors });
    }

    return res.formVaildCheck;
  };

  /** Form Submit Functionality Here */
  const registerUser = async () => {
    if ((await checkFormIsValid("")) && suggestions.length === 0) {
      try {
        dispatch(callCommonAction({ loading: true }));
        let countForm = formStep;
        const stripe = await stripePromise;
        countForm = countForm + 1;
        let body = { ...userDetail };
        if (formStep === 2) {
          body.payment_method_id = "";
          if (body.amount > 0) {
            let paymentMethod = await checkoutFormRef.current.handleCardElement(); //Call stripe Api
            if (paymentMethod && paymentMethod.id) {
              body.payment_method_id = paymentMethod.id;
              body.payment_method_data = paymentMethod;
              setErrorsInfo((prev) => ({ ...prev, card: "" }));
            } else {
              setErrorsInfo((prev) => ({ ...prev, card: paymentMethod.message, }));
              dispatch(callCommonAction({ loading: false }));
              return false;
            }
          }

          const response = await sendRequest(`/user/create-payment-intent`, "POST", body);
          body.clientSecret = response.data.paymentIntent.client_secret;
          body.customerId = response.data.paymentIntent.customer;

          const paymentIntent = await stripe.confirmCardPayment(body.clientSecret, { payment_method: body.payment_method_id });
          body.paymentIntent = paymentIntent;
          const res = await sendRequest(`/user/register`, "POST", body);
          handleApiResponse(res);
          if (res.data.type === "success") {
            setFormStep(countForm);
          }
        } else {
          setFormStep(countForm);
        }
        dispatch(callCommonAction({ loading: false }));
      } catch (error) {
        console.log("error", error);
        toast.error("Something went wrong");
        dispatch(callCommonAction({ loading: false }));
      }
    }
  };

  const preStepCall = () => setFormStep(formStep - 1);

  const calculateAmount = (e) => {
    const { value, name } = e.target;
    userDetail["credits"] = value;
    userDetail["amount"] =
      parseFloat(value) * parseFloat(userDetail.average_price);
    setUserDetail(userDetail);
    checkFormIsValid(name);
  };

  const decodeJWT = async (response) => {
    try {
      const googleAccessToken = response.credential;
      dispatch(callCommonAction({ loading2: true }));
      const res = await sendRequest('/user/register-by-google', 'POST', { token: googleAccessToken });
      dispatch(callCommonAction({ loading2: false }));
      handleApiResponse(res);
      if (res.data.type === "success") {
        localStorage.setItem("token", res.data.access_token);
        localStorage.setItem('user', JSON.stringify(res.data.data));
        localStorage.setItem('isAdmin', res.data.data.role === 1);
        dispatch(callCommonAction({ isLoggedIn: true, user: res.data.data, token: res.data.access_token, isAdmin: res.data.data.role === 1 }));
      }
    } catch (error) {
      toast.error(error.message);
      dispatch(callCommonAction({ loading2: false }));
    }
  };


  return (
    <>
      <Helmet>
        <title>Leadboost | Register Form</title>
        <meta name="description" content="Register Me" />
        <meta name="keywords" content="Game, Entertainment, Movies" />
      </Helmet>
      <form
        className="bg-white d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep last"
        id="kt_create_account_stepper"
        data-kt-stepper="true"
      >
        <div className="d-flex flex-column flex-lg-row-auto w-lg-350px w-xl-500px">
          <div className="d-flex flex-column position-lg-fixed top-0 bottom-0 w-lg-350px w-xl-500px scroll-y ggg bgi-size-cover bgi-position-center login-screen-bg">
            <div className="d-flex flex-center py-10 py-lg-20 mt-lg-20">
              <a
                href={undefined}
                onClick={() => navigate(appRoutes.homePageRoute)}
              >
                <img alt="Logo" src="/iquote-white.png" className="h-50px" />
              </a>
            </div>
            <div className="d-flex flex-row-fluid justify-content-center p-10">
              <div className="stepper-nav">
                <div
                  className={`stepper-item ${formStep === 1 ? "current" : ""
                    }  ${formStep > 1 ? "completed" : ""} `}
                  data-kt-stepper-element="nav"
                >
                  <div className="stepper-wrapper">
                    <div className="stepper-icon rounded-3">
                      <i className="ki-duotone ki-check fs-2 stepper-check" />
                      <span className="stepper-number">1</span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title fs-2">Account Info</h3>
                      <div className="stepper-desc fw-normal">
                        Setup your account settings
                      </div>
                    </div>
                  </div>
                  <div className="stepper-line h-40px"></div>
                </div>
                <div
                  className={`stepper-item ${formStep === 2 ? "current" : ""
                    }  ${formStep > 2 ? "completed" : ""} `}
                  data-kt-stepper-element="nav"
                >
                  <div className="stepper-wrapper">
                    <div className="stepper-icon">
                      <i className="ki-duotone ki-check fs-2 stepper-check" />
                      <span className="stepper-number">2</span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title ">Billing Details</h3>
                      <div className="stepper-desc fw-normal">
                        Provide your payment info
                      </div>
                    </div>
                  </div>
                  <div className="stepper-line h-40px"></div>
                </div>
                <div
                  className={`stepper-item ${formStep === 3 ? "current" : ""}`}
                  data-kt-stepper-element="nav"
                >
                  <div className="stepper-wrapper">
                    <div className="stepper-icon">
                      <i className="ki-duotone ki-check fs-2 stepper-check" />
                      <span className="stepper-number">3</span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title ">Completed</h3>
                      <div className="stepper-desc fw-normal">
                        Your account is created
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-center flex-wrap px-5 py-10">
              <div className="d-flex fw-normal">
                {/* <a href={undefined} className="text-white-50 px-5" target="_blank">Terms</a> */}
               {/*  <a
                  href={undefined}
                  onClick={() =>
                    navigate(appRoutes.homePageRoute, {
                      state: { priceScrollViewReach: true },
                    })
                  }
                  className="text-white-50 px-5"
                  target="_blank"
                >
                  Plans
                </a> */}
                <a
                  href={undefined}
                  onClick={() => navigate(appRoutes.contactUsRoute)}
                  className="text-white-50 px-5"
                  target="_blank"
                >
                  Contact Us
                </a>
                <a
                  href={undefined}
                  onClick={() => navigate(appRoutes.loginRoute)}
                  className="text-white-50 px-5"
                  target="_blank"
                >
                  Login
                </a>
                {/* <Link to="/user/login">Go to About Page</Link> */}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-lg-row-fluid py-10">
          <div className="d-flex flex-center flex-column flex-column-fluid">
            <div className="w-lg-650px w-xl-700px p-10 p-lg-15 mx-auto">
              <div
                className="my-auto pb-5 fv-plugins-bootstrap5 fv-plugins-framework"
                id="kt_create_account_form"
                data-select2-id="select2-data-kt_create_account_form"
              >
                {/* Tab 1 START */}
                <div
                  className={`${formStep === 1 ? "current" : ""}`}
                  data-kt-stepper-element="content"
                >
                  <div className="w-100">
                    <div className="pb-10 pb-lg-15">
                      <h2 className="fw-bold text-gray-900">Account Info</h2>
                      {/* <div className="text-muted fw-semibold fs-6">
												If you need more info, please check out
												<a href="#" className="link-primary fw-bold">Help Page</a>.
											</div> */}
                    </div>
                    <div className="row">
                      <div className="fv-row mb-8 col">
                        <label className="form-label fw-bold text-gray-900 fs-6">
                          First Name
                        </label>
                        <input
                          placeholder="Enter first name"
                          type="text"
                          autoComplete="off"
                          name="first_name"
                          className={`form-control bg-transparent ${errorsInfo.first_name ? "is-invalid" : ""
                            }`}
                          onChange={handleChange}
                          value={userDetail.first_name}
                        />
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert" />
                            {errorsInfo.first_name}
                          </div>
                        </div>
                      </div>
                      <div className="fv-row mb-8 col">
                        <label className="form-label fw-bold text-gray-900 fs-6">
                          Last Name
                        </label>
                        <input
                          placeholder="Enter last name"
                          type="text"
                          autoComplete="off"
                          name="last_name"
                          className={`form-control bg-transparent ${errorsInfo.last_name ? "is-invalid" : ""
                            }`}
                          onChange={handleChange}
                          value={userDetail.last_name}
                        />
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert" />
                            {errorsInfo.last_name}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">

                      <div className="fv-row mb-8 col">
                        <label className="form-label fw-bold text-gray-900 fs-6">
                          Email
                        </label>
                        <input
                          placeholder="Email"
                          type="email"
                          autoComplete="off"
                          name="email"
                          className={`form-control bg-transparent ${errorsInfo.email ? "is-invalid" : ""
                            }`}
                          onChange={handleChange}
                          value={userDetail.email}
                        />
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert" />
                            {errorsInfo.email}
                          </div>
                        </div>
                      </div>
                      <div className="fv-row mb-8 col">
                        <label className="form-label fw-bold text-gray-900 fs-6">
                          Phone Number
                        </label>
                        <Input
                          country="US"
                          placeholder="Phone Number"
                          className={`form-control bg-transparent ${errorsInfo.phonenumber ? "is-invalid" : ""
                            }`}
                          onChange={(e) => handlePhoneChange(e)}
                          value={userDetail.phonenumber}
                        />
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert" />
                            {errorsInfo.phonenumber}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div
                        className="fv-row mb-8 col"
                        data-kt-password-meter="true"
                      >
                        <div className="mb-1">
                          <label className="form-label fw-bold text-gray-900 fs-6">
                            Password
                          </label>
                          <div className="position-relative mb-3">
                            <input
                              type="password"
                              placeholder="Password"
                              autoComplete="off"
                              name="password"
                              className={`form-control bg-transparent ${errorsInfo.password ? "is-invalid" : ""
                                }`}
                              onChange={handleChange}
                              value={userDetail.password}
                            />
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <span role="alert">{errorsInfo.password}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="fv-row mb-5 col">
                        <label className="form-label fw-bold text-gray-900 fs-6">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          placeholder="Confirm Password"
                          autoComplete="off"
                          name="password_confirmation"
                          className={`form-control bg-transparent ${errorsInfo.password_confirmation ? "is-invalid" : ""
                            }`}
                          onChange={handleChange}
                          value={userDetail.password_confirmation}
                        />
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">
                              {errorsInfo.password_confirmation}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Tab 1 END */}

                {/* Tab 2 START */}
                <div
                  className={`${formStep === 2 ? "current" : ""}`}
                  data-kt-stepper-element="content"
                >
                  <div className="w-100" data-select2-id="select2-data-26-byp7">
                    <div className="pb-5">
                      <h2 className="fw-bolder text-gray-900">
                        Billing Details
                      </h2>
                      {/* <div className="text-muted fw-semibold fs-6">
                        If you need more info, please check out
                        <a
                          href={undefined}
                          onClick={() => {
                            navigate(appRoutes.homePageRoute, {
                              state: { priceScrollViewReach: true },
                            });
                          }}
                          className="text-primary fw-bold"
                        >
                          {" "}
                          Price Page
                        </a>
                        .
                      </div> */}
                    </div>
                    <div

                      className="mb-7 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid"
                      style={{
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        padding: "20px 10px",
                        borderRadius: "10px",
                      }}
                    >
                      <label
                        className="d-flex align-items-center form-label mb-5 "
                        style={{ fontWeight: "bold" }}
                      >
                        Purchase Credits (Default {userDetail?.minimum_credits} credits)
                      </label>
                      <input
                        type="number"
                        min={userDetail?.minimum_credits}
                        style={{
                          padding: "2px 8px",
                          border: "2px solid #ccc",
                          borderRadius: "5px",
                          fontSize: "16px",
                          transition: "border-color 0.3s ease",
                        }}
                        placeholder="Enter credits..."
                        value={userDetail.credits}
                        onChange={calculateAmount}
                        name="credits"
                      />

                      <span className="">
                        {" "}
                        <strong>
                          $ {parseFloat(userDetail.amount).toFixed(2)}
                        </strong>
                      </span>
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{errorsInfo.credits}</span>
                        </div>
                      </div>

                    </div>

                    <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                      <div
                        className="position-relative mt-3"
                        style={{
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                          padding: "30px 20px",
                          borderRadius: "10px",
                        }}
                      >
                        <Elements stripe={stripePromise}>
                          <CheckoutForm
                            ref={checkoutFormRef}
                            errorsInfo={errorsInfo}
                          />
                        </Elements>
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{errorsInfo.card}</span>
                          </div>
                        </div>
                      </div>
                      <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                    </div>
                    <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                      <label className="form-check form-check-inline">
                        <input
                          className={`form-check-input ${errorsInfo.terms ? "is-invalid" : ""
                            }`}
                          type="checkbox"
                          name="terms"
                          value="false"
                          onChange={handleTermsChange}
                        />
                        <span>
                          I Accept the{" "}
                          <a
                            href={undefined}
                            target="_blank"
                            className="ms-1 link-primary cursor-pointer"
                          >
                            Terms
                          </a>
                          .
                        </span>
                      </label>
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{errorsInfo.terms}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Tab 2 END */}

                {/* Tab 3 START */}
                <div
                  className={`${formStep === 3 ? "current" : ""}`}
                  data-kt-stepper-element="content"
                >
                  <table
                    align="center"
                    border={0}
                    cellPadding={0}
                    cellSpacing={0}
                    width="100%"
                    height="auto"
                    style={{ borderCollapse: "collapse" }}
                  >
                    <tbody>
                      <tr>
                        <td
                          align="center"
                          valign="center"
                          style={{ textAlign: "center", paddingBottom: "10px" }}
                        >
                          <div
                            style={{
                              textAlign: "center",
                              margin: "0 60px 34px 60px",
                            }}
                          >
                            <div style={{ marginBottom: "10px" }}>
                              <a
                                href={undefined}
                                onClick={() =>
                                  navigate(appRoutes.homePageRoute)
                                }
                                rel="noopener"
                              >
                                <img
                                  alt="Logo"
                                  src="/Logo-iQuote.png"
                                  style={{ height: "35px" }}
                                />
                              </a>
                            </div>
                            <div style={{ marginBottom: "15px" }}>
                              <img alt="Logo" src="/icon-positive-vote-3.svg" />
                            </div>
                            <div
                              style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                marginBottom: "42px",
                              }}
                            >
                              <p
                                style={{
                                  marginBottom: "9px",
                                  color: "#181C32",
                                  fontSize: "22px",
                                  fontWeight: 700,
                                }}
                              >
                                Hey{" "}
                                {userDetail.first_name +
                                  " " +
                                  userDetail.last_name}
                                , thanks for signing up!
                              </p>
                              <p
                                style={{
                                  marginBottom: "2px",
                                  color: "#7E8299",
                                }}
                              >
                                To proceed to access your account and explore
                                our platform further,
                              </p>
                              <p
                                style={{
                                  marginBottom: "2px",
                                  color: "#7E8299",
                                }}
                              >
                                please click on Login button
                              </p>
                            </div>

                            <a
                              href={undefined}
                              onClick={() => navigate(appRoutes.loginRoute)}
                              className="btn btn-primary"
                            >
                              Login
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          align="center"
                          valign="center"
                          style={{
                            fontSize: "13px",
                            textAlign: "center",
                            padding: "0 10px 10px 10px",
                            fontWeight: 500,
                            color: "#A1A5B7",
                          }}
                        >
                          <p
                            style={{
                              color: "#181C32",
                              fontSize: "16px",
                              fontWeight: 600,
                              marginBottom: "9px",
                            }}
                          >
                            It’s all about customers!
                          </p>
                          <p style={{ marginBottom: "2px" }}>
                            Call our customer care number: +xx xxxx xx 56
                          </p>
                          <p style={{ marginBottom: "4px" }}>
                            You may reach us at{" "}
                            <a
                              href="#"
                              rel="noopener"
                              target="_blank"
                              style={{ fontWeight: 600 }}
                            >

                              support@iquote.live
                            </a>
                            .
                          </p>
                          <p>We serve Mon-Fri, 9AM-18AM</p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          align="center"
                          valign="center"
                          style={{ textAlign: "center", paddingBottom: "20px" }}
                        >
                          <a href={undefined} style={{ marginRight: "10px" }}>
                            <img alt="Logo" src="/icon-linkedin.svg" />
                          </a>
                          <a href={undefined} style={{ marginRight: "10px" }}>
                            <img alt="Logo" src="/icon-facebook.svg" />
                          </a>
                          <a href={undefined}>
                            <img alt="Logo" src="/icon-twitter.svg" />
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* Tab  END */}
                {formStep === 3 ? null : (
                  <div className="d-flex justify-content-between pt-15 w-100">

                    {formStep !== 1 ? (
                      <div className="mr-2">
                        <button
                          onClick={preStepCall}
                          type="button"
                          className="btn btn-sm btn-light-primary me-3"
                          data-kt-stepper-action="previous"
                        >
                          <i className="ki-duotone ki-arrow-left fs-4 me-1">
                            <span className="path1" />
                            <span className="path2" />
                          </i>{" "}
                          Previous
                        </button>
                      </div>
                    ) : <React.Fragment>
                      <GoogleLogin
                        onSuccess={decodeJWT}
                        onError={(error) => {
                          toast.error('Login Failed', error);
                        }}
                        useOneTap
                        theme={'filled_blue'}
                        auto_select
                      />
                    </React.Fragment>}



                    <button
                      onClick={registerUser}
                      type="button"
                      id="kt_sign_up_submit"
                      className="btn btn-sm btn-primary"
                      style={{ minWidth: '142px' }}
                      disabled={loading ? true : false}
                    >
                      {loading ? (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      ) : (
                        <>

                          <span className="indicator-label">
                            {formStep === 2
                              ?
                              "Submit"
                              :
                              "Continue"
                            }
                          </span>
                          {formStep === 1 &&
                            <i className="ki-duotone ki-arrow-right fs-4 ms-1">
                              <span className="path1" />
                              <span className="path2" />
                            </i>
                          }
                        </>
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form >
    </>
  );
};

export default Register;
