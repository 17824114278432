import STRINGS from "../../../common/strings/strings";

export function validateCreditFormData(creditSettingData) {
    const { credit_per_request,credit_per_request_voucher, credit_per_request_insurance, credit_per_request_driver_record, amount_per_credit, default_purchase_credit, mimimum_threshold_credits, credit_per_request_full_contact, credit_per_request_dnc } = creditSettingData;
    const errors = {};
    let formValidCheck = true;

    // Validation rules
    if (!credit_per_request || credit_per_request <= 0) {
        errors.credit_per_request = STRINGS.nonZeroReq;
        formValidCheck = false;
    }
    if (!amount_per_credit || amount_per_credit <= 0) {
        errors.amount_per_credit = STRINGS.nonZeroReq;
        formValidCheck = false;
    }
    if (!default_purchase_credit || default_purchase_credit <= 0) {
        errors.default_purchase_credit = STRINGS.nonZeroReq;
        formValidCheck = false;
    }
    if (!mimimum_threshold_credits || mimimum_threshold_credits <= 0) {
        errors.mimimum_threshold_credits = STRINGS.nonZeroReq;
        formValidCheck = false;
    }
    if (!credit_per_request_voucher || credit_per_request_voucher <= 0) {
        errors.credit_per_request_voucher = STRINGS.nonZeroReq;
        formValidCheck = false;
    }
    if (!credit_per_request_full_contact || credit_per_request_full_contact <= 0) {
        errors.credit_per_request_full_contact = STRINGS.nonZeroReq;
        formValidCheck = false;
    }
    if (!credit_per_request_dnc || credit_per_request_dnc <= 0) {
        errors.credit_per_request_dnc = STRINGS.nonZeroReq;
        formValidCheck = false;
    }
    if (!credit_per_request_driver_record || credit_per_request_driver_record <= 0) {
        errors.credit_per_request_driver_record = STRINGS.nonZeroReq;
        formValidCheck = false;
    }
    if (!credit_per_request_insurance || credit_per_request_insurance <= 0) {
        errors.credit_per_request_insurance = STRINGS.nonZeroReq;
        formValidCheck = false;
    }

    return { formValidCheck, errors };
}
