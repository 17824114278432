import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Badge from 'react-bootstrap/Badge';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { sendRequest } from '../../../apis/APIs';
import { handleApiResponse } from '../../../apis/apiUtils';
import appSettings from '../../../configs/AppConfig';
import { Helmet } from 'react-helmet';
import Pagination from '../../../common/Pagination/pagination';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import appRoutes from '../../../configs/AppRoutes';


const List = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [paginationData, setPaginationData] = useState({ perpage: appSettings.recordsPerPageTable, page: 1, totalCount: 0 });
    const [filter, setFilter] = useState({ search_string: '', status: '', date: '' });
    const [paymentList, setUserPaymentList] = useState([]);
    const [overallRevenue, setOverallRevenue] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [startDate, setStartDate] = useState(moment().startOf('year').subtract(1, 'year'));
    const [endDate, setEndDate] = useState(moment());
    const [focusedInput, setFocusedInput] = useState(null);

    useEffect(() => {
        if (filter.search_string.length > 2 || filter.search_string === "") {
            getPaymentReport(1, startDate, endDate);
        }
    }, [filter.search_string]);

    const getPaymentReport = async (page, startDate, endDate) => {
        try {
            startDate = moment(startDate).unix();
            endDate = moment(endDate).unix();
            dispatch(callCommonAction({ loading: true, breadCrumb: ['Payment', 'List'] }));
            const res = await sendRequest(`/payment/list/`, 'GET', { page: page, limit: paginationData.perpage, startDate: startDate, endDate: endDate });
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                setUserPaymentList(res.data.data.docs);
                setOverallRevenue(res.data.overallRevenue);
                setPaginationData({ ...paginationData, totalCount: res.data.data.totalDocs, page: res.data.data.page });
            }
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading: false }));
        }
    };

    const handleDateChange = ({ startDate, endDate }) => {
        setStartDate(startDate);
        setEndDate(endDate);
        getPaymentReport(1, new Date(startDate), new Date(endDate));
        // if (startDate !== null && endDate !== null) {
        //     getDashboard(new Date(startDate), new Date(endDate));
        // }
    };

    const handleSubmit = event => {
        event.preventDefault();
        // You can use selectedDate in your form submission
        console.log(selectedDate);
    };

    const getStatusBadgeColor = (status) => {
        switch (status) {
            case 'succeeded':
                return 'success';
            case 'requires_payment_method':
                return 'warning';
            case 'cancel':
                return "danger";
            case 'processing':
                return "primary";
            case 'requires_capture':
                return "secondary";
            default:
                return 'primary';
        }
    };

    const renderTabeBody = () => {
        return paymentList.map((payment) => {
            // Convert timestamps to Date objects
            const startDate = new Date(payment.created * 1000); // Multiply by 1000 to convert seconds to milliseconds
            //const endDate = new Date(payment.current_period_end * 1000);

            // Format dates as strings
            const formattedStartDate = startDate.toLocaleDateString();
            //const formattedEndDate = endDate.toLocaleDateString();
            return (
                <tr key={payment.id}>
                    <td className="d-flex align-items-center">
                        {/**begin:: Avatar **/}
                        <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                            <a href={undefined}>
                                <div className="symbol-label">
                                    <img
                                        src={
                                            payment.created_by && payment.created_by.user_image
                                                ? process.env.REACT_APP_ASSET_ENDPOINT +
                                                "/" +
                                                payment.created_by.user_image
                                                : "/assets/media/avatars/blank.png"
                                        }
                                        alt="Emma Smith"
                                        className="w-100"
                                    />
                                </div>
                            </a>
                        </div>
                        {/**end::Avatar**/}
                        {/**begin::User details**/}
                        <div className="d-flex flex-column">
                            <a
                                href={undefined}
                                className="text-gray-800 text-hover-primary mb-1"
                                onClick={() =>
                                    navigate(appRoutes.adminUserDetailRoute + "/" + payment.created_by._id)
                                }
                            >
                                {payment.created_by?.first_name +' '+ payment.created_by?.last_name}
                            </a>
                            <span>{payment.created_by?.email}</span>
                        </div>
                        {/**begin::User details**/}
                    </td>
                    <td>{payment.credits} </td>
                    <td className="">{`$${parseFloat(payment.credits_details.amount_per_credit).toFixed(2)}`}</td>
                    <td className=''>{`$` + parseFloat(payment.amount).toFixed(2)}</td>
                    <td>{formattedStartDate} </td>
                    <td className='text-end'>
                        <Badge bg={getStatusBadgeColor(payment.status)} className='text-white'>
                            {payment.status}
                        </Badge>
                    </td>

                </tr>
            );

        });

    };

    return (
        <>
            {/*begin::Content*/}
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <Helmet>
                    <title>leadBoost | Payment List</title>
                    <meta name="description" content="Description goes here" />
                    <meta name="keywords" content="Game, Entertainment, Movies" />
                </Helmet>
                {/*begin::Post*/}
                <div className="post d-flex flex-column-fluid" id="kt_post">
                    {/*begin::Container*/}
                    <div id="kt_content_container" className="container-xxl">

                        {/*begin::Pricing card*/}
                        <div className="row g-10 mb-12 justify-content-center">
                            <div className="col-xl-4 ">
                                <div className="d-flex h-100 align-items-center">
                                    <div className="bg-light bg-opacity-75 border d-flex flex-center flex-column px-10 py-5 rounded-3 w-100">
                                        <div className="text-center">
                                            <h1 className="text-gray-900 fw-bolder fs-3">Overall revenue</h1>
                                            <div className="text-center">
                                                <span className="mb-2 text-primary">$</span>
                                                <span className="fs-3x fw-bold text-primary">{parseFloat(overallRevenue).toFixed(2)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card" id="kt_pricing">
                            {/**begin::Card header**/}
                            <div className="card-header">
                                {/**begin::Card title**/}
                                <div className="card-title">
                                    {/**begin::Search**/}

                                </div>
                                <div className="card-toolbar">
                                    {/**begin::Daterangepicker(defined in src/js/layout/app.js)**/}
                                    <div className="btn btn-sm btn-light d-flex align-items-center px-4">
                                        {/**begin::Display range**/}
                                        <div className="text-gray-600 w-100 fw-bold fs-14 custom-date-pick">
                                            <DateRangePicker
                                                startDate={startDate}
                                                startDateId="start_date"
                                                endDate={endDate}
                                                endDateId="end_date"
                                                onDatesChange={handleDateChange}
                                                focusedInput={focusedInput}
                                                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                                                isOutsideRange={() => false} // Allow all dates
                                                displayFormat="MM/DD/YYYY"
                                            />
                                        </div>
                                        {/**end::Display range**/}

                                    </div>

                                    {/**end::Daterangepicker**/}
                                </div>
                                {/**begin::Card title**/}
                            </div>
                            {/**end::Card header**/}
                            {/**begin::Card body**/}
                            <div className="card-body table-frame py-4">
                                {/**begin::Table**/}
                                <div className='table-responsive'>
                                    <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_users">
                                        <thead>
                                            <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                                                <th className="min-w-125px">Agent</th>
                                                <th className="min-w-125px">Credit</th>
                                                <th className="min-w-125px">AMOUNT/PER CREDIT</th>
                                                <th className="min-w-125px">Amount</th>
                                                <th className="min-w-125px">Payment Date</th>
                                                <th className="min-w-125px text-end">Status</th>

                                            </tr>
                                        </thead>
                                        <tbody className="text-gray-600 fw-semibold">
                                            {paymentList.length > 0 ? renderTabeBody() : <tr>
                                                <td colSpan={7} className='text-center'>No result found!</td>
                                            </tr>}
                                        </tbody>
                                    </table>
                                </div>
                                {/**end::Table**/}
                                <Pagination className="pagination-bar" currentPage={paginationData.page} totalCount={paginationData.totalCount}
                                    pageSize={paginationData.perpage} onPageChange={page => getPaymentReport(page, startDate, endDate)}
                                />
                            </div>
                            {/**end::Card body**/}
                        </div>
                        {/*end::Pricing card*/}
                    </div>
                    {/*end::Container*/}
                </div>
                {/*end::Post*/}
            </div>
            {/*end::Content*/}
        </>

    );
};


export default List;