import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet";
import { sendRequest } from '../../../apis/APIs';

const List = () => {

    const [filter, setFilter] = useState({ search_string: "", status: "all" });
    const { user } = useSelector((state) => state.common);
    const dispatch = useDispatch();
    const [cards, setCards] = useState([]);

    useEffect(() => {
      alert()
        getUserCards();
    }, []);

    const getUserCards = async () => {
        try {
            dispatch(callCommonAction({ loading: true }));
            const res = await sendRequest(`/payment/card-list`, 'GET',{});
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading: false }));
        }
    }

    return (
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          {/**begin::Post**/}
          <div className="post d-flex flex-column-fluid" id="kt_post">
            {/**begin::Container**/}
            <div id="kt_content_container" className="container-xxl">
              {/**begin::Card**/}
              <div className="card">
                {/**begin::Card header**/}
                <div className="card-header border-0 pt-6">
                  {/**begin::Card title**/}
                  <div className="card-title">
                    {/**begin::Search**/}
                    <div className="d-flex align-items-center position-relative my-1">
                      <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      <input
                        type="text"
                        data-kt-user-table-filter="search"
                        value={filter.search_string}
                        onChange={(e) =>
                          setFilter({ ...filter, search_string: e.target.value })
                        }
                        className="form-control form-control-solid w-250px ps-13 border"
                        placeholder="Search user"
                      />
                    </div>
                    {/**end::Search**/}
                  </div>
                  {/**begin::Card title**/}
                  {/**begin::Card toolbar**/}
                  <div className="card-toolbar">
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center me-2">
                        <label className="form-label fw-semibold mb-0 me-2">
                          Status:
                        </label>
                        <div>
                          <select
                            className="form-select form-select-solid fw-bold"
                            value={filter.status}
                            onChange={(e) =>
                              setFilter({ ...filter, status: e.target.value })
                            }
                          >
                            <option value="all">All</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </select>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          type="reset"
                          className="btn btn-light btn-active-light-primary me-2"
                          //onClick={clearFilter}
                        >
                          Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          //onClick={applyFilter}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
    
                    {/**end::Toolbar**/}
                    {/**begin::Group actions**/}
                    <div
                      className="d-flex justify-content-end align-items-center d-none"
                      data-kt-user-table-toolbar="selected"
                    >
                      <div className="fw-bold me-5">
                        <span
                          className="me-2"
                          data-kt-user-table-select="selected_count"
                        ></span>
                        Selected
                      </div>
                      <button
                        type="button"
                        className="btn btn-danger"
                        data-kt-user-table-select="delete_selected"
                      >
                        Delete Selected
                      </button>
                    </div>
                    {/**end::Group actions**/}
                  </div>
                  {/**end::Card toolbar**/}
                </div>
                {/**end::Card header**/}
                {/**begin::Card body**/}
                <div className="card-body table-frame py-4 border-top mt-5">
                  {/**begin::Table**/}
                  <div className="table-responsive">
                    <table
                      className="table align-middle table-row-dashed fs-6 gy-5"
                      id="kt_table_users"
                    >
                      <thead>
                        <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                          <th className="min-w-125px">Agent</th>
                          <th className="min-w-125px">Joined Date</th>
                          <th className="min-w-125px text-center">Status</th>
                          <th className="text-end min-w-100px">Actions</th>
                        </tr>
                      </thead>
                      {/* <tbody className="text-gray-600 fw-semibold">
                        {users.length > 0 ? (
                          renderTabeBody()
                        ) : (
                          <tr>
                            <td colSpan={4} className="text-center">
                              No result found!
                            </td>
                          </tr>
                        )}
                      </tbody> */}
                    </table>
                  </div>
                  {/**end::Table**/}
                  {/* <Pagination
                    className="pagination-bar"
                    currentPage={paginationData.page}
                    totalCount={paginationData.totalCount}
                    pageSize={paginationData.perpage}
                    onPageChange={(page) => getUsers(page, filter.status)}
                  />
                  {showEditModal ? userUpdateForm() : null} */}
                </div>
                {/**end::Card body**/}
              </div>
              {/**end::Card**/}
            </div>
            {/**end::Container**/}
          </div>
          {/**end::Post**/}
        </div>
      );
};


export default List;