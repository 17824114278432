import React from 'react';
import appRoutes from './../configs/AppRoutes';
import { Routes, Route } from 'react-router-dom';
import ForgotPassword from './../components/ForgotPassword/ForgotPassword';
import Login from './../components/Login/Login';
import Home from './../components/Home/Home';
import NoRouteFound from './../components/NoRouteFound';
import Register from './../components/Register/Register';
import ResetPassword from './../components/ResetPassword/ResetPassword';
import Dashboard from './../components/User/Dashboard/Dashboard';
import Overview from './../components/Profile/Overview';
import CardList from './../components/User/Card/List';
import ContactUs from '../components/CMS/ContactUs';
import AboutUs from '../components/CMS/AboutUs';
import PaymentSetting from '../components/User/Payment/Setting';
import ManageCrm from '../components/User/ManageCrm';
import PurchaseCredit from '../components/User/Credits/PurchaseCredit'
import EnrichList from '../components/User/Enrich/List'
import MarketPlaceCallBack from '../components/User/Enrich/MarketPlaceCallBack'
import LeadDetail from '../components/User/Lead/Detail'
import EnrichDetail from '../components/User/Enrich/Detail'
import Voucher from '../components/Voucher/Manage';


// Containers
const DefaultLayout = React.lazy(() => import('./../layout/DefaultLayout'));
const AuthLayout = React.lazy(() => import('./../layout/AuthLayout'));
const CmsLayout = React.lazy(() => import('./../layout/CmsLayout'));

const UserRoute = () => {
    return (
        <Routes>
            {/* LoggedIn Routes Start */}
            <Route exact path="/user/" element={<AuthLayout />} >
                <Route index element={<Dashboard />} />
                <Route path={appRoutes.dashboardRoute} element={<Dashboard />} />
                <Route path={appRoutes.profileRoute} element={<Overview />} />
                <Route path={appRoutes.cardListRoute} element={<CardList />} />
                <Route path={appRoutes.paymentSettingRoute} element={<PaymentSetting />} />
                <Route path={appRoutes.crmSettingsRoute} element={<ManageCrm />} />
                <Route path={appRoutes.purchaseCreditRoute} element={<PurchaseCredit />} />
                <Route path={appRoutes.enrichmentRoute} element={<EnrichList />} />
                <Route path={appRoutes.leadDetail + "/:id"} element={<LeadDetail />} />
                <Route path={appRoutes.enrichDetail + "/:id"} element={<EnrichDetail />} />
                <Route path={appRoutes.marketPlaceCallBack} element={<MarketPlaceCallBack />} />
                <Route path={appRoutes.voucherRoute} element={<Voucher />} />
            </Route>
            {/* LoggedIn Routes End */}

            {/* Without Logged In Routes Start */}
            <Route exact path="/user/" element={<DefaultLayout />} >
                <Route exact path={appRoutes.loginRoute} element={<Login />} />
                <Route exact path={appRoutes.registerRoute} element={<Register />} />
                <Route exact path={appRoutes.forgotPasswordRoute} element={<ForgotPassword />} />
                <Route exact path={appRoutes.resetPasswordRoute + "/:token"} element={<ResetPassword />} />
            </Route>
            {/* Without Logged In Routes Start */}

            <Route exact path="/" element={<CmsLayout />} >
                <Route exact index path={appRoutes.homePageRoute} element={<Home />} />
                <Route exact path={appRoutes.contactUsRoute} element={<ContactUs />} />
                <Route exact path={appRoutes.aboutUsRoute} element={<AboutUs />} />
            </Route>

            <Route path='*' element={<NoRouteFound />} />

        </Routes>
    );

};

export default UserRoute;
