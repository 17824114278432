import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { sendRequest } from '../../../apis/APIs';
import { handleApiResponse } from '../../../apis/apiUtils';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import appRoutes from '../../../configs/AppRoutes';
import { Helmet } from 'react-helmet';

const Detail = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [leadData, setLeadData] = useState({});
    const [fullContactData, setFullContactData] = useState({});
    const [dncCheck, setDncCheck] = useState(false);
    const { loading2, loading3 } = useSelector((state) => state.common);

    useEffect(() => {
        getLeadDetail();
    }, []);

    const getLeadDetail = async () => {
        try {
            dispatch(callCommonAction({ loading: true, breadCrumb: ['Lead', 'Detail'] }));
            const res = await sendRequest('/lead/detail/' + params.id, 'GET');
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                setLeadData(res.data.data);
                if (res.data.data.full_contact_detail) {
                    setFullContactData(res.data.data.full_contact_detail);
                }
            }
        } catch (error) {
            //console.log('dfsd', error.message);
            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading: false }));
        }
    };

    const getFullContactData = async () => {
        try {
            dispatch(callCommonAction({ loading2: true }));
            let body = { email: leadData?.email, lead_id: params.id };
            const res = await sendRequest('/lead/full-contact', 'POST', body);
            dispatch(callCommonAction({ loading2: false }));
            handleApiResponse(res);
            if (res.data.type === "success") {
                setFullContactData(res.data.data);
            }
        } catch (error) {
            //console.log('dfsd', error.message);
            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading2: false }));
        }
    };

    const getDncStatus = async () => {
        try {
            dispatch(callCommonAction({ loading3: true }));
            let body = { phone: leadData?.phone, lead_id: params.id };
            const res = await sendRequest('/lead/dnc-check', 'POST', body);
            dispatch(callCommonAction({ loading3: false }));
            handleApiResponse(res);
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading3: false }));
        }
    };

    const renderFullContactData = () => {

        return (

            <React.Fragment className='border p-5 rounded mb-5 shadow-sm'>
                <div className="border-bottom fs-3 fw-semibold mb-5 pb-2">Full Contact Detail</div>
                <div className="row">
                    <div className="fs-6 fw-semibold mb-5 text-muted col-12 col-md-6 col-xl-3"><span className="text-body">First Name:</span> {fullContactData.details.name?.given}</div>
                    <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3"><span className="text-body">Last Name:</span> {fullContactData.details.name?.family}</div>
                    <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3"><span className="text-body">Full Name:</span> {fullContactData?.fullName}</div>
                    <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3"><span className="text-body">Organization:</span> {fullContactData?.organization}</div>
                </div>
                {fullContactData.details?.locations?.map((location, index) => {
                    return (
                        <div className="row">
                            <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3"><span className="text-body">City:</span> {location?.city}</div>
                            <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3"><span className="text-body">RegionCode:</span> {location?.regionCode}</div>
                            <div className="fs-6 fw-semibold mb-5 text-muted col-12 col-md-6 col-xl-3"><span className="text-body">Country:</span> {location?.country}</div>
                            <div className="fs-6 fw-semibold mb-5 text-muted col-12 col-md-6 col-xl-3"><span className="text-body">Country Code:</span> {location?.countryCode}</div>

                        </div>
                    );
                })}
            </React.Fragment>


        );
    };

    return (
        <div>
            <Helmet>
                <title>Leadboost | Lead Detail</title>
                <meta name="description" content="Description goes here" />
                <meta name="keywords" content="Game, Entertainment, Movies" />
            </Helmet>
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content"><div id="kt_content_container" className="container-xxl">
                <div className="card mb-6 mb-xl-9">
                    {/**begin::Card header**/}
                    <div className="card-header">
                        {/**begin::Card title**/}
                        <div className="card-title flex-column">
                            <h2 className="mb-1">Leads Info</h2>
                        </div>
                        {/**end::Card title**/}
                        {/**begin::Card toolbar**/}
                        <div className="card-toolbar">
                            <span className="btn btn-primary btn-sm" onClick={() => navigate(-1)}><i className="ki-duotone ki-left"></i>Back</span>
                        </div>
                        {/**end::Card toolbar**/}
                    </div>
                    {/**end::Card header**/}
                    {/**begin::Card body**/}
                    <div className="card-body">
                        <div className=''>
                            <div className="border-bottom fs-3 fw-semibold mb-5 pb-2">General Information</div>
                            <div className="row">
                                <div className="fs-6 fw-semibold mb-5 text-muted col-12 col-md-6 col-xl-3"><span className="text-body">First Name:</span> {leadData?.firstName}</div>
                                <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3"><span className="text-body">Last Name:</span> {leadData?.lastName}</div>
                                <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3"><span className="text-body">Email:</span> {leadData?.email}</div>
                                <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3"><span className="text-body">Phone:</span> {leadData?.phone}</div>
                            </div>
                            <div className="row">
                                <div className="fs-6 fw-semibold mb-5 text-muted col-12 col-md-6 col-xl-3"><span className="text-body">Date of Birth:</span> {moment(leadData.dateOfBirth).format('MM/DD/YYYY')} </div>
                                <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3"><span className="text-body">Postal Code:</span> {leadData?.postalCode}</div>
                                <div className="fs-6 fw-semibold mb-5 text-muted col-12 col-md-6 col-xl-3"><span className="text-body">country:</span> {leadData?.country}</div>

                                {/* <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3"><span className="text-body">Address:</span> {leadData?.address1}</div> */}
                                <div className="fs-6 fw-semibold text-muted mb-5 col-12 col-md-6 col-xl-3"><span className="text-body">Created Date:</span> {moment(leadData.created_at).format('MM/DD/YYYY')}</div>
                            </div>

                            <div className="my-5 text-right">
                                {
                                    !leadData.full_contact_detail
                                        ?
                                        <span className="btn btn-primary btn-sm mb-4 me-2" onClick={() => getFullContactData()}>


                                            {
                                                loading2
                                                    ?
                                                    <span className="indicator-progress" style={{ display: 'block' }}>
                                                        Please wait...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                    :
                                                    <span className="indicator-label">Check Full Contact Detail</span>
                                            }


                                        </span>
                                        :
                                        ""
                                }
                                {fullContactData && fullContactData.hasOwnProperty('details') ? renderFullContactData() : ''}
                                <span className="btn btn-primary btn-sm mb-4" onClick={() => getDncStatus()}>
                                    {
                                        loading3
                                            ?
                                            <span className="indicator-progress" style={{ display: 'block' }}>
                                                Please wait...
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                            :
                                            <span className="indicator-label">Check DNC</span>
                                    }
                                </span>
                            </div>
                        </div>
                    </div>

                    {/**end::Card body**/}
                </div>
            </div>
            </div>
        </div>
    );
};

export default Detail;
