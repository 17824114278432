import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { sendRequest } from '../../apis/APIs';
import appRoutes from '../../configs/AppRoutes';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validateForgotPasswordFormData } from './Validation';
import { handleApiResponse } from '../../apis/apiUtils';
import { Helmet } from 'react-helmet';


const ForgotPassword = () => {
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState({ email: '' });
  const loading = useSelector((state) => state.common.loading);
  const dispatch = useDispatch();
  const [errorsInfo, setErrorsInfo] = useState({});
  const isAdminUrl = window.location.href.includes('admin');

  /** Forgot Password Form OnChange */
  const handleChange = (e) => {
    userDetail[e.target.name] = e.target.value;
    setUserDetail(userDetail);
    checkFormIsValid();
  };

  /** Validation implemented here */
  const checkFormIsValid = () => {
    const res = validateForgotPasswordFormData(userDetail);
    setErrorsInfo(res.errors);
    return res.formVaildCheck;
  };

  /** Forgot Password Form Submit */
  const forgotPasswordUser = async (e) => {
    e.preventDefault();
    if (checkFormIsValid(userDetail)) {
      try {
        dispatch(callCommonAction({ loading: true }));
        let reqUrl = isAdminUrl ? '/admin/forgot-password' : `/user/forgot-password`;
        const res = await sendRequest(reqUrl, 'POST', userDetail);
        dispatch(callCommonAction({ loading: false }));
        handleApiResponse(res);
        if (res.data.type === "success") {
          setUserDetail({ email: '' });
        }
      } catch (error) {
        toast.error('Something went wrong');
        dispatch(callCommonAction({ loading: false }));
      }
    }
  };

  return (
    <div>
      <Helmet>
        <title>Leadboost | Forgot Password</title>
        <meta name="description" content="Description goes here" />
        <meta name="keywords" content="Game, Entertainment, Movies" />
      </Helmet>
      <form
        className="form w-100"
        noValidate
        onSubmit={forgotPasswordUser}
        id="kt_sign_in_form"
      >
        <div className="text-center mb-11">
          <h1 className="text-gray-900 fw-bolder mb-3">Forgot Password ?</h1>
        </div>
        <div className="fv-row mb-8">
          <input
            type="text"
            placeholder="Email"
            name="email"
            autoComplete="off"
            className={`form-control bg-transparent ${errorsInfo.email ? 'is-invalid' : ''}`}
            onChange={handleChange}
            value={userDetail.email}
          />
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{errorsInfo.email}</span>
            </div>
          </div>
        </div>
        <div className="d-grid mb-10">
          <button
            type="submit"
            id="kt_sign_up_submit"
            className="btn btn-lg btn-primary w-100 mb-5"
            disabled={loading ? true : false}
          >
            {
              loading
                ?
                <span className="indicator-progress" style={{ display: 'block' }}>
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
                :
                <span className="indicator-label">Submit</span>
            }

          </button>
          <button
            type="button"
            id="kt_login_signup_form_cancel_button"
            className="btn btn-lg btn-light-primary w-100 mb-5"
            onClick={() => navigate(isAdminUrl ? appRoutes.adminLoginRoute : appRoutes.loginRoute)}
          >
            Cancel
          </button>
        </div>

      </form>
    </div>
  );
};

export default ForgotPassword;
