import React, {  useEffect } from 'react';
import './Home.css';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import appRoutes from '../../configs/AppRoutes';


const Home = () => {
  const navigate = useNavigate();

  useEffect(() =>{
    // For now, If someone try to access homepage we can redirect to login page  
    navigate(appRoutes.loginRoute)
  },[])
  
  return (
    <div>
      <Helmet>
        <title>Leadboost | Home page</title>
        <meta name="description" content="Description goes here" />
        <meta name="keywords" content="Game, Entertainment, Movies" />
      </Helmet>
      {/* Hero section START from here */}
      <section className="hero-section">
        <div className="container">
          <div className="row align-items-center hero-content">
            <div className="col-md-6 col-xl-5 hero-text-sec">
              <p className="sub">Introducing</p>
              <h1 className="hero-heading mb-8">the most powerful revenue-generating <strong>insurance quoting
                machine ever</strong> released</h1>
              <a href={undefined} onClick={() => navigate(appRoutes.landingPageMultiStepFormRoute)} className="hero-btn">Get Now</a>
            </div>
            <div className="col-md-6 col-xl-7 hero-img-sec">
              <img className="img-fluid" src={`${process.env.REACT_APP_FRONTEND}/assets/home/hero-img.png`} alt="Image" />
            </div>
          </div>
        </div>
      </section>
      {/* Hero section END here */}
      <section className="video-sec">
        <div className="container">
          <div className="text-center">
            <h2>What Makes <img className="img-fluid" src={`${process.env.REACT_APP_FRONTEND}/assets/home/text-logo.png`} alt=""  /> The Best?</h2>
            <img className="img-fluid poster" src={`${process.env.REACT_APP_FRONTEND}/assets/home/video-frame.png`} alt=""  />
          </div>
        </div>
      </section>
      <section className="alternating-row">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="row-content justify-content-center">
                <img src={`${process.env.REACT_APP_FRONTEND}/assets/home/img-1.png`} alt="Image 1" className="row-img mw-100" />
              </div>
            </div>
            <div className="col-md-9">
              <div className="row-content">
                <div>
                  <h2 className="row-heading">It Starts With the Setup...</h2>
                  <p className="row-text">As soon as your prospect fills in their contact information, iQuote asks
                    what products they are interested in. This list, controlled by you, sets every single
                    prospect up for multiple opportunities to sell them products.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="alternating-row">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-3 order-md-2">
              <div className="row-content justify-content-center">
                <img src={`${process.env.REACT_APP_FRONTEND}/assets/home/img-2.png`} alt="Image 2" className="row-img" />
              </div>
            </div>
            <div className="col-md-9 order-md-1">
              <div className="row-content">
                <div>
                  <h2 className="row-heading">Data Collection Made Easy!</h2>
                  <p className="row-text">The data collection form is dynamically generated using artificial
                    intelligence to collect all of the data needed for a quote, with only four questions
                    being asked at a time. As the form is submitted all of the data is saved instantly. If
                    the prospect leaves halfway through, you still have all of the data they entered in
                    before they left.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="alternating-row">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="row-content justify-content-center">
                <img src={`${process.env.REACT_APP_FRONTEND}/assets/home/img-3.png`} alt="Image 3" className="row-img" />
              </div>
            </div>
            <div className="col-md-9">
              <div className="row-content">
                <div>
                  <h2 className="row-heading">Instant Price Quote Available for Selected Products</h2>
                  <p className="row-text">We have relationships with 120+ carriers and can instantly give your
                    prospect a price range for the products they're interested in. The quotes are stored in
                    the database and are easily retrieved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="alternating-row">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-3 order-md-2">
              <div className="row-content justify-content-center">
                <img src={`${process.env.REACT_APP_FRONTEND}/assets/home/img-4.png`} alt="Image 4" className="row-img" />
              </div>
            </div>
            <div className="col-md-9 order-md-1">
              <div className="row-content">
                <div>
                  <h2 className="row-heading">Referrals, Referrals, Referrals!</h2>
                  <p className="row-text">The iQuote Offers feature gives you the ability to select which
                    opportunities your prospects see after they're done filling in the form for the
                    insurance products they're interested in. Examples include home security systems,
                    refinancing home loans, debt reduction, and more! We want to empower you to send
                    referrals to your best partners, and this gives your prospects the chance to tell you
                    what they're interested in.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="alternating-row">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="row-content justify-content-center">
                <img src={`${process.env.REACT_APP_FRONTEND}/assets/home/img-5.png`} alt="Image 5" className="row-img" />
              </div>
            </div>
            <div className="col-md-9">
              <div className="row-content">
                <div>
                  <h2 className="row-heading">iQuote Anywhere</h2>
                  <p className="row-text">Your iQuote Anywhere Form can be used... anywhere. You can add it to an
                    existing website, link to it on your social media platforms, and even post the link to
                    your form on your business card.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="alternating-row">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-3 order-md-2">
              <div className="row-content justify-content-center">
                <img src={`${process.env.REACT_APP_FRONTEND}/assets/home/img-7.png`} alt="Image 6" className="row-img" />
              </div>
            </div>
            <div className="col-md-9 order-md-1">
              <div className="row-content">
                <div>
                  <h2 className="row-heading">Build Up Your Reputation</h2>
                  <p className="row-text">On the last screen of the iQuote Anywhere Form, your prospects are also
                    given the opportunity to leave a review on your ReviewMyInsuranceAgent profile, which
                    will help you build up your online presence. We love the ReviewMyInsuranceAgent platform
                    because it's so easy to use, and your prospects can easily post their review to your
                    Facebook Page, and Google My Business Page.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="alternating-row">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="row-content justify-content-center">
                <img src={`${process.env.REACT_APP_FRONTEND}/assets/home/img-6.png`} alt="Image 7" className="row-img" />
              </div>
            </div>
            <div className="col-md-9">
              <div className="row-content">
                <div>
                  <h2 className="row-heading">Lead to Appointment: We Make it Easy</h2>
                  <p className="row-text">When you setup your iQuote Anywhere Form, you are directed to input the
                    link for your calendar. At the end of the form submission process, your prospect is told
                    to schedule a call with you for the next steps. This button opens up your online booking
                    system in another window. This link can guide them to your Agent CRM calendar, or your
                    Calendly link, or any other online booking system you use. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="plan-section" id="destination">
        {/* <div className="container">
          <div className="row align-items-center justify-content-center align-items-lg-stretch">
            {planList.length > 0 && renderPlanList()}
          </div>
        </div> */}
      </section>
      <section className="products-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-4">
              <h2>Our <br />Products</h2>
            </div>
            <div className="col-sm-6 col-lg-4">
              <ul>
                <li>iQuote Upseller</li>
                <li>IQuote Instant Data Bridge</li>
                <li>iQuote Live</li>
              </ul>
            </div>
            <div className="col-sm-6 col-lg-4">
              <ul>
                <li>iQuote Referral Engine</li>
                <li>iQuote Anywhere Form</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
