import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callCommonAction } from "../../../redux/Common/CommonReducer";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import Badge from "react-bootstrap/Badge";
import { sendRequest } from "../../../apis/APIs";
import { loadStripe } from "@stripe/stripe-js";
import { handleApiResponse } from "../../../apis/apiUtils";
import appSettings from "../../../configs/AppConfig";
import Pagination from "../../../common/Pagination/pagination";
import { Helmet } from "react-helmet";
import Form from "react-bootstrap/Form";
import appRoutes from "../../../configs/AppRoutes";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

const PurchaseCredit = () => {
	const navigate = useNavigate();
	const { user } = useSelector((state) => state.common);
	const [paymentList, setPaymentList] = useState([]);
	const [creditEnable, setCreditEnable] = useState(false);
	const [creditSetting, setCreditSetting] = useState({
		amount: 0,
		credit: 0,
		minimum_credits: 0,
		average_price: 0,
		user_id: user?.id,
		stripe_customer: user?.stripe_customer,
	});
	const [cards, setCards] = useState([]);
	const [filter, setFilter] = useState({ search_string: "", status: "all" });
	const [paginationData, setPaginationData] = useState({ perpage: appSettings.recordsPerPageTable, page: 1, totalCount: 0 });
	const [errors, setErrors] = useState({});
	const dispatch = useDispatch();
	const [userDetail, setUserDetail] = useState(null);

	useEffect(() => {
		getCreditAmountData();
	}, []);

	useEffect(() => {
		if (filter.search_string.length > 2 || filter.search_string === "") {
			getPaymentList(1, filter.status);
		}
	}, [filter.search_string]);

	useEffect(() => {
		getUserCards();
	}, [setCards]);

	const getPaymentList = async (page, status) => {
		try {
			dispatch(callCommonAction({ loading: true, breadCrumb: ["Payments", "List"] }));
			const res = await sendRequest(`/payment/list`, "GET", { page: page, limit: paginationData.perpage, search_string: filter.search_string, status: status, });
			dispatch(callCommonAction({ loading: false }));
			handleApiResponse(res);
			if (res.data.type === "success") {
				setPaymentList(res.data.data.docs);
				setPaginationData({ ...paginationData, totalCount: res.data.data.totalDocs, page: res.data.data.page, });
			}
		} catch (error) {
			//console.log('error', error);
			toast.error("Something went wrong");
			dispatch(callCommonAction({ loading: false }));
		}
	};
	const getStatusBadgeColor = (status) => {
		switch (status) {
			case "succeeded":
				return "success";
			case "requires_payment_method":
				return "warning";
			case "cancel":
				return "danger";
			case "processing":
				return "primary";
			case "requires_capture":
				return "secondary";
			default:
				return "primary";
		}
	};



	const getCreditAmountData = async () => {
		try {
			dispatch(callCommonAction({ loading: true }));
			const res = await sendRequest("/admin/get-credit", "GET", {});
			dispatch(callCommonAction({ loading: false }));
			if (res.data.type === "success") {
				let amt = res.data.data.default_purchase_credit * res.data.data.amount_per_credit;
				setCreditSetting({
					...creditSetting,
					credit: res.data.data.default_purchase_credit,
					amount: amt.toFixed(2),
					average_price: res.data.data.average_price,
					minimum_credits: res.data.data.default_purchase_credit,
					credit_detail: res.data.data
				});
			}
		} catch (error) {
			toast.error("Something went wrong");
			dispatch(callCommonAction({ loading: false }));
		}
	};


	const renderTabeBody = () => {
		return paymentList.map((payment) => {
			const startDate = new Date(payment.created * 1000);
			const formattedStartDate = startDate.toLocaleDateString();
			return (
				<tr key={payment.id}>
					{user != null && user.role == 1 && (
						<td className="d-flex align-items-center">
							{/**begin:: Avatar **/}
							<div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
								<div className="symbol-label">
									<img
										src={
											payment.created_by && payment.created_by.user_image
												? process.env.REACT_APP_ASSET_ENDPOINT +
												"/" +
												payment.created_by.user_image
												: "/assets/media/avatars/blank.png"
										}
										alt={payment.created_by?.fullname}
										className="w-100"
									/>
								</div>
							</div>
							{/**end::Avatar**/}
							{/**begin::User details**/}
							<div className="d-flex flex-column">
								<a
									href={undefined}
									className="text-gray-800 text-hover-primary mb-1"
								>
									{payment.created_by?.first_name +
										" " +
										payment.created_by?.last_name}
								</a>
								<span>{payment.created_by?.email}</span>
							</div>
							{/**begin::User details**/}
						</td>
					)}
					{/* <td>{payment.stripe_subscription_id}</td> */}

					<td>{formattedStartDate} </td>
					<td className="">{parseFloat(payment.credits)}</td>
					<td className="">{`$${parseFloat(payment.credits_details.amount_per_credit).toFixed(2)}`}</td>
					<td className="">{`$` + parseFloat(payment.amount).toFixed(2)}</td>
					<td className="text-end">
						<Badge
							bg={getStatusBadgeColor(payment.status)}
							className="text-white"
						>
							{payment.status}
						</Badge>
					</td>
				</tr>
			);
		});
	};




	const getUserCards = async () => {
		try {
			dispatch(callCommonAction({ loading: true }));
			const res = await sendRequest(`/payment/card-list`, "GET", {});
			if (res.data.type === "success") {
				setCreditEnable(Boolean(res.data.userDetail.credit_enable));
				setCards(res.data.data);
				setUserDetail(res.data.userDetail);
			}

			dispatch(callCommonAction({ loading: false }));
		} catch (error) {
			console.log("error", error);
			toast.error("Something went wrong");
			dispatch(callCommonAction({ loading: false }));
		}
	};


	const handleSubmit = async () => {
		try {

			if (userDetail.is_card_enter === 1) {
				if (checkFormIsValid(creditSetting)) {
					dispatch(callCommonAction({ loading: true }));
					const stripe = await stripePromise;
					const res = await sendRequest("/user/purchase-credit", "POST", creditSetting);
					if (res.data.data && res.data.data.client_secret) {
						const cardData = res.data.cardData[0];
						const paymentIntent = await stripe.confirmCardPayment(res.data.data.client_secret, { payment_method: cardData.payment_method_id });
						const resData = await sendRequest("/user/confirm-card-payment", "POST", { ...creditSetting, paymentIntent, credits: creditSetting.credit });
						handleApiResponse(resData);
					} else {
						handleApiResponse(res);
					}
					await getPaymentList(1, 'all');
					dispatch(callCommonAction({ loading: false }));
				}
			} else {
				toast.error("Please provide credit card details before submitting.");
				navigate(appRoutes.paymentSettingRoute)
			}
		} catch (error) {
			toast.error("Something went wrong");
			dispatch(callCommonAction({ loading: false }));
		}
	};

	const checkFormIsValid = (credSet) => {
		let errors = {};
		let formIsValid = true;
		if (!credSet.credit || parseFloat(credSet.credit) < parseFloat(credSet.minimum_credits)) {
			errors.credit = `Your should be choose minimum ${credSet.minimum_credits} credits`;
			formIsValid = false;
		}
		setErrors(errors);
		return formIsValid;
	};


	const calculateAmount = (e) => {
		const { value, name } = e.target;
		const credits = parseFloat(value) >= 0 ? parseFloat(value) : 0; // Convert value to a number
		const amount = credits * parseFloat(creditSetting.credit_detail.amount_per_credit);
		const duplObj = { ...creditSetting };
		duplObj['credit'] = credits;
		duplObj['amount'] = amount.toFixed(2);
		setCreditSetting({
			...creditSetting,
			credit: credits,
			amount: amount.toFixed(2), // Format amount to 2 decimal places if needed
		});
		checkFormIsValid(duplObj);
	};


	const handleCreditEnable = async (e) => {
		try {
			dispatch(callCommonAction({ loading: true, alert: null }));
			const res = await sendRequest(`/user/rebilling-setting`, "POST", { credit_enable: e.target.checked });
			if (res.data.type === "success") {
				setCreditEnable(res.data.data.credit_enable);
			}
			handleApiResponse(res);
			dispatch(callCommonAction({ loading: false }));
		} catch (error) {
			dispatch(callCommonAction({ loading: false }));
			console.error("Error handling input change:", error);
			toast.error("Something went wrong");
		}
	};

	return (
		<>
			{console.log('creditEnable', creditEnable)}
			<div className="content d-flex flex-column flex-column-fluid col-md-12" id="kt_content"  >
				<Helmet>
					<title>leadBoost | Purchase Credits</title>
					<meta name="description" content="Description goes here" />
					<meta name="keywords" content="Game, Entertainment, Movies" />
				</Helmet>

			</div>
			<div className="post d-flex flex-column-fluid" id="kt_post">
				<div id="kt_content_container" className="container">
					<div className="d-flex flex-column flex-md-row mt-8">
						{
							userDetail !== null && userDetail.is_card_enter === 1
								?
								<div className="col-md-3 mb-5 mb-md-0">
									{cards
										.filter((card) => card.is_default === 1)
										.map((card) => (
											<div
												key={card.id}
												className={`card ${card.is_default === 1 ? 'border border-primary' : 'card-dashed'}  flex-row flex-stack flex-wrap py-5  me-5 h-100`}
											>
												<div className="d-flex flex-column py-3">
													<div className="d-flex align-items-center fs-5 fw-bold mb-5">
														{card.owner}{" "}
														{card.is_default && (
															<span
																className={`badge ${card.is_default === 1 ? "badge-light-success" : "badge-light-success"} fs-8 ms-2 `}
															>
																{card.is_default === 1 ? "Primary" : ""}
															</span>
														)}
													</div>
													<div className="d-flex align-items-center">
														<img
															src={`assets/media/svg/card-logos/${card.brand.toLowerCase()}.svg`}
															alt=""
															className="me-4"
														/>
														<div>
															<div className="fs-5 fw-bold">
																{card.brand} **** {card.last4}
															</div>
															<div className="fs-8 fw-semibold text-gray-500">
																Card expires at {card.exp_month}/{card.exp_year}
															</div>
														</div>
													</div>
												</div>
											</div>
										))}
								</div>
								:
								null

						}

						<div className={`card col-md-${userDetail !== null && userDetail.is_card_enter === 1 ? 9 : 12}`} id="kt_pricing" >
							<div className="card-body  py-4 d-flex align-items-center flex-wrap h-m-auto w-m-100 h-100">
								<div className="col-md-4 pe-md-4 w-m-100">
									<div className="fv-row  fv-plugins-icon-container">
										<label className="fs-6 fw-semibold form-label m-0">
											<span className="required pe-3 fw-bolder">Credits</span>
										</label>
										<input
											type="number"
											className="form-control"
											value={creditSetting.credit}
											min={creditSetting?.minimum_credits}
											style={{
												padding: "10px",
												borderRadius: "5px",
												border: "1px solid #ccc",
												maxWidth: "300px", // Adjust the maximum width as needed
											}}
											onChange={(e) => calculateAmount(e)}
											placeholder="Enter credits..."
											name="credit"
										/>
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">
												<span role="alert">{errors.credit}</span>
											</div>
										</div>
										<button className="btn btn-primary mt-2 btn-sm" onClick={() => handleSubmit()}> Buy </button>
									</div>


								</div>
								<div className="border-end pe-md-4 col-md-4 d-flex align-items-center justify-content-center w-m-100 mt-m-3">
									<div className="w-m-100">
										<div className="card card-xl-stretch p-5 theme-dark-bg-body w-m-100" style={{ backgroundColor: '#25aae1', height: "100px" }}>
											{/*begin::Body*/}
											<div className="card-body d-flex flex-column justify-content-center p-0">
												{/*begin::Wrapper*/}
												<div className="d-flex flex-column ">
													{/*begin::Title*/}
													<a href={undefined} className="text-white text-hover-primary fw-bold fs-4">Calculation</a>
													{/*end::Title*/}
												</div>
												{/*end::Wrapper*/}

												{/*begin::Stats*/}
												<div className="">
													{/*begin::Symbol*/}
													<span className="text-white fw-bold lh-0" style={{ fontSize: "15px" }}>$</span>
													{/*end::Symbol*/}
													<span className="text-white me-1  fw-bold" style={{ fontSize: "21px" }} > {creditSetting.amount}</span>

													{/*begin::Number*/}
													{/*end::Number*/}

													{/*begin::Text*/}
													<span className="text-white fw-bold fs-7 lh-0">{`to be paid for ${creditSetting.credit} credits`}</span>
													{/*end::Text*/}
												</div>
												{/*end::Stats*/}
											</div>
											{/*end::Body*/}
										</div>
									</div>
								</div>

								{/*begin::Post*/}

								<div className="align-items-center ps-md-4 col-md-4 d-flex justify-content-center mt-m-3 w-m-100" id="kt_post">
									<div className="card w-100" id="kt_pricing">
										<div className="align-items-center card-body d-flex">
											<div className="col-md-12">
												<div className="fv-plugins-icon-container fv-row">
													<label className="form-label fs-6 fw-semibold"><span className="required pe-3 fw-bolder">Credit Setting</span></label>
													<div className="fv-row">
														<Form.Check
															type="switch"
															id="rebillingSwitch"
															label="Enable Rebilling"
															checked={Boolean(creditEnable)}
															onChange={(e) => handleCreditEnable(e)}
														/>
													</div>
													<div className="invalid-feedback px-2"></div>
												</div>
											</div>
										</div>
									</div>
								</div>


								{/*end::Post*/}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="post d-flex flex-column-fluid" id="kt_post">
				{/*begin::Container*/}
				<div id="kt_content_container" className="container-xxl">
					{/*begin::Pricing card*/}
					<div className="card" id="kt_pricing">
						{/**begin::Card header**/}
						<div className="card-header">
							{/**begin::Card title**/}
							<div className="card-title">
								{/**begin::Search**/}

								{user != null && user.role == 1 ? (
									<div className="d-flex align-items-center position-relative my-1">
										<i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
											<span className="path1"></span>
											<span className="path2"></span>
										</i>
										<input
											type="text"
											data-kt-user-table-filter="search"
											value={filter.search_string}
											onChange={(e) =>
												setFilter({
													...filter,
													search_string: e.target.value,
												})
											}
											className="form-control form-control-solid w-250px ps-13 border"
											placeholder="Search user"
										/>
									</div>
								) : (
									"My Payments"
								)}
							</div>
							{/**begin::Card title**/}
						</div>
						{/**end::Card header**/}
						{/**begin::Card body**/}
						<div className="card-body table-frame py-4">
							{/**begin::Table**/}
							<div className="table-responsive">
								<table
									className="table align-middle table-row-dashed fs-6 gy-5"
									id="kt_table_users"
								>
									<thead>
										<tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
											{user != null && user.role == 1 && (
												<th className="min-w-125px">Email</th>
											)}
											<th className="min-w-125px">Payment Date</th>
											<th className="min-w-125px">Credits</th>
											<th className="min-w-125px">Amount/Per credit</th>
											<th className="min-w-125px">Total Amount</th>
											<th className="min-w-125px text-end ">Status</th>
										</tr>
									</thead>
									<tbody className="text-gray-600 fw-semibold">
										{paymentList.length > 0 ? (
											renderTabeBody()
										) : (
											<tr>
												<td colSpan={7} className="text-center">
													No result found!
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							{/**end::Table**/}
							{/**end::Table**/}
							<Pagination
								className="pagination-bar"
								currentPage={paginationData.page}
								totalCount={paginationData.totalCount}
								pageSize={paginationData.perpage}
								onPageChange={(page) => getPaymentList(page, filter.status)}
							/>
							{/**end::Table**/}
						</div>
						{/**end::Card body**/}
					</div>
					{/*end::Pricing card*/}
				</div>
				{/*end::Container*/}
			</div>
		</>

	);

};
export default PurchaseCredit;
