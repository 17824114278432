import React, { useState } from 'react';
import { validateCmdFormData } from './Validation';
import { isValidPhoneNumber } from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';
import { useDispatch, useSelector } from 'react-redux';
import { handleApiResponse } from '../../apis/apiUtils';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import { sendRequest } from '../../apis/APIs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

const ContactUs = () => {
  const [userDetail, setUserDetail] = useState({ phonenumber: "", first_name: "", last_name: "", email: "", message: "" });
  const [formErrors, setFormErrors] = useState({});
  const { loading } = useSelector((state) => state.common);
  const dispatch = useDispatch();

  /** OnChange Update Input Values */
  const handleChange = (e) => {
    userDetail[e.target.name] = e.target.value;
    setUserDetail(userDetail);
    checkFormIsValid(e.target.name);
  };

  /* Validation implemented here */
  const checkFormIsValid = (fieldName) => {
    const res = validateCmdFormData('contact-us', userDetail, fieldName, isValidPhoneNumber);
    setFormErrors(res.errors);
    return res.formVaildCheck;
  };

  /** Submit Form Data */
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (checkFormIsValid('')) {
      try {
        dispatch(callCommonAction({ loading: true }));
        const res = await sendRequest('/cms/contact-us-form-submit', 'POST', userDetail);
        dispatch(callCommonAction({ loading: false }));
        handleApiResponse(res);
        if (res.data.type === "success") {
          setUserDetail({ phonenumber: "", first_name: "", last_name: "", email: "", message: "" });
        }
      } catch (error) {
        toast.error(error.message);
        dispatch(callCommonAction({ loading: false }));
      }
    }
  };


  /** handlePhoneChange */
  const handlePhoneChange = (phonenumber) => {
    if (phonenumber) {
      userDetail.phonenumber = phonenumber;
      setUserDetail(userDetail);
      checkFormIsValid("phonenumber");
    }
  };

  return (
    <>
      <Helmet>
        <title>Leadboost | Contact Us</title>
        <meta name="description" content="Description goes here" />
        <meta name="keywords" content="Game, Entertainment, Movies" />
      </Helmet>
      <section className='inner-banner'>
        <div className='container'>
          <h1 className='text-center text-white fs-2hx'>Contact Us</h1>
        </div>
      </section>
      <div className="container">
        <div className='row'>
          <div className='col-lg-5 get-touch px-0'>
            <div className='p-12 pb-8 pb-lg-3'>
            <img src={`${process.env.REACT_APP_FRONTEND}/assets/home/contact-img2.png`} alt="Image" className="mb-8" />
              <h2>Get In Touch!</h2>
              <p className='fs-5 text-white mb-10'>Please use this form to contact us and we will get back to you as soon as possible!</p>
              <div className='text-white'>
                <div className="d-flex align-items-center fs-5 mb-4">
                  <i className="ki-duotone ki-phone me-2 fs-2 ">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                  <p className='mb-0'>+61 2521 XXXX</p>
                </div>
                <div className="d-flex align-items-center fs-5">
                  <i className="ki-duotone ki-send me-2 fs-2">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                  <p className='mb-0'>support@iquote.live</p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-7 px-0'>
            <form onSubmit={handleSubmit} className='bg-white shadow-xs p-12 border-5 h-100'>
              <div className="row">
                <div className="fv-row mb-8 col-lg-6">
                  <label className="form-label fw-bolder text-gray-900 fs-6">First Name</label>
                  <input
                    placeholder="Enter first name"
                    type="text"
                    autoComplete="off"
                    name="first_name"
                    className={`form-control bg-transparent ${formErrors.first_name ? 'is-invalid' : ''}`}
                    onChange={handleChange}
                    value={userDetail.first_name}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block"><span role="alert" />{formErrors.first_name}</div>
                  </div>
                </div>
                <div className="fv-row mb-8 col-lg-6">
                  <label className="form-label fw-bolder text-gray-900 fs-6">Last Name</label>
                  <input
                    placeholder="Enter last name"
                    type="text"
                    autoComplete="off"
                    name="last_name"
                    className={`form-control bg-transparent ${formErrors.last_name ? 'is-invalid' : ''}`}
                    onChange={handleChange}
                    value={userDetail.last_name}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block"><span role="alert" />{formErrors.last_name}</div>
                  </div>
                </div>
                <div className="fv-row mb-8 col-lg-6">
                  <label className="form-label fw-bolder text-gray-900 fs-6">Phone Number</label>
                  <Input
                    country="US"
                    placeholder="Phone Number"
                    className={`form-control bg-transparent ${formErrors.phonenumber ? 'is-invalid' : ''}`}
                    onChange={(e) => handlePhoneChange(e)}
                    value={userDetail.phonenumber}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block"><span role="alert" />{formErrors.phonenumber}</div>
                  </div>
                </div>
                <div className="fv-row mb-8 col-lg-6">
                  <label className="form-label fw-bolder text-gray-900 fs-6">Email</label>
                  <input
                    placeholder="Email"
                    type="email"
                    autoComplete="off"
                    name="email"
                    className={`form-control bg-transparent ${formErrors.email ? 'is-invalid' : ''}`}
                    onChange={handleChange}
                    value={userDetail.email}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block"><span role="alert" />{formErrors.email}</div>
                  </div>
                </div>
                <div className="fv-row mb-8 col-12">
                  <label className="form-label fw-bolder text-gray-900 fs-6">Message</label>

                  <textarea style={{ height: '100px' }} onChange={handleChange} className={`form-control bg-transparent ${formErrors.message ? 'is-invalid' : ''}`} value={userDetail.message} name="message"></textarea>
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block"><span role="alert" />{formErrors.message}</div>
                  </div>
                </div>
                <div className='text-end'>
                  <button type="submit" id="kt_sign_up_submit" className="btn btn-lg btn-primary mb-5" disabled={loading ? true : false} >
                    {
                      loading
                        ?
                        <span className="indicator-progress" style={{ display: 'block' }}>
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                        :
                        <span className="indicator-label d-inline-flex align-items-center">Submit</span>
                    }
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactUs;
