const appRoutes = {
    homePageRoute : '/',
    aboutUsRoute : '/about-us',
    contactUsRoute : '/contact-us',
    
    /**User Links */
    loginRoute : '/user/login',
    registerRoute : '/user/register',
    forgotPasswordRoute : '/user/forgot-password',
    resetPasswordRoute : '/user/reset-password',
    paymentSettingRoute: '/user/payment-setting',
    dashboardRoute : '/user/dashboard',
	profileRoute: '/user/profile',
    crmSettingsRoute : '/user/crm-settings',
    purchaseCreditRoute : '/user/purchase-credit',
    paymentHistoryRoute : '/user/payment-history',
    cardListRoute : '/user/card-list',
    enrichmentRoute : '/user/enrich',
    leadDetail : '/user/lead-detail',
    enrichDetail : '/user/enrich-detail',
    enrichleadDetail : '/user/enrich-lead-detail',
    marketPlaceCallBack : '/user/oauth/callback',
    voucherRoute: '/user/voucher',



      
    /**Admin Links */
    adminPaymentHistoryRoute : '/admin/payment-history/user', 
    adminLoginRoute : '/admin/login',
    adminForgotPasswordRoute : '/admin/forgot-password',
    adminResetPasswordRoute : '/admin/reset-password',
    adminDashboardRoute : '/admin/dashboard',
    adminErrorReportRoute: '/admin/error-report',
    adminVoucherRoute: '/admin/voucher',
    adminUserListRoute : '/admin/user-list',
    adminProfileRoute: '/admin/profile',
    adminUserDetailRoute: '/admin/user',
    adminCreditRoute: '/admin/credit',
    adminPaymentListRoute: '/admin/payment-list',
    adminPageVideoRoute: '/admin/page-video',
    
}
export default appRoutes