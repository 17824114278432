import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callCommonAction } from "../../../redux/Common/CommonReducer";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { sendRequest } from "../../../apis/APIs";
import { loadStripe } from "@stripe/stripe-js";
import { handleApiResponse } from "../../../apis/apiUtils";
import appSettings from "../../../configs/AppConfig";
import { useNavigate } from "react-router-dom";
import Pagination from '../../../common/Pagination/pagination';
import moment from 'moment';
import Dropdown from "react-bootstrap/Dropdown";
import appRoutes from "../../../configs/AppRoutes";




export default function List() {
    const [leads, setLeads] = useState({});
    const [filter, setFilter] = useState({ search_string: "", status: "all" });
    const [enrichmentRequest, setEnrichmentRequest] = useState({ query: '' });
    const [paginationData, setPaginationData] = useState({ perpage: appSettings.recordsPerPageTable, page: 1, totalCount: 0 });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (filter.search_string.length > 2 || filter.search_string === "") getUserLeads(1, filter.status);
    }, [filter.search_string]);


    const handleSubmit = async () => {
        try {
            dispatch(callCommonAction({ loading: true, breadCrumb: ['Enrichment', 'Section'] }));
            const res = await sendRequest("/user/enrichment-request", "POST", enrichmentRequest);
            dispatch(callCommonAction({ loading: false }));
            if (res.data.type === "success") {
                if(res.data.data.contacts.length === 0){
                    toast.error('No results found for Enrichment');
                }else{
                    setEnrichmentRequest({ query: ''});
                    navigate(`${appRoutes.enrichDetail}/${res.data.enrich_id}`)
                }
            }else{
                handleApiResponse(res);
            }
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading: false }));
        }
    };

    const getUserLeads = async (page, status) => {
        try {
            dispatch(callCommonAction({ loading: true, breadCrumb: ['Enrichment', 'Section'] }));
            const res = await sendRequest(`/lead/list`, 'GET', { page: page, limit: paginationData.perpage, search_string: filter.search_string, status: status, });
            dispatch(callCommonAction({ loading: false }));
            handleApiResponse(res);

            if (res.data.type === "success") {
                setLeads(res.data.data.docs);
                setPaginationData({ ...paginationData, totalCount: res.data.data.totalDocs, page: res.data.data.page });
            }
        } catch (error) {
            toast.error('Something went wrong');
            dispatch(callCommonAction({ loading: false }));
        }
    };


    const renderTabeBody = () => {
        return leads.map((lead) => {
            let badgeCls = lead.status === 1 ? 'success' : 'danger';
            return (
                <tr key={lead.id}>
                    <td className="min-w-125px"> <span>{lead.contactName}</span></td>
                    <td className="min-w-125px"> <span>{lead.email}</span></td>
                    <td className="min-w-125px"> <span>{lead.phone}</span></td>
                    <td className="min-w-125px">{moment(new Date(lead.created_at)).format('MM/DD/YYYY')}</td>
                    <td className="min-w-125px text-center"> <span className={`text-uppercase badge badge-light-${badgeCls}`}>{lead.status_name}</span></td>
                    <td className="text-end">
                        <Dropdown>
                            <Dropdown.Toggle
                                className="btn btn-light btn-active-light-primary btn-sm"
                                id="dropdown-basic"
                            >
                                Actions
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    href={undefined}
                                    onClick={() =>
                                        navigate(appRoutes.leadDetail + "/" + lead._id)
                                    }
                                >
                                    View
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>
            );
        });
    };

    return (
        <>
            <div className="post d-flex flex-column-fluid" id="kt_post">
                <div id="kt_content_container" className="container-xxl">
                    <div className="card mt-5 py-4" id="kt_pricing">
                        <div className="card-body  py-4 d-flex align-items-center" >
                            <div className="border-end col-md-12 pe-md-4">
                                <div className="fv-row  fv-plugins-icon-container d-flex align-items-center">
                                    <label className="fs-6 fw-semibold form-label m-0">
                                        <span className="pe-3 fw-bolder">Data Enrichment Request</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        style={{
                                            padding: "10px",
                                            borderRadius: "5px",
                                            border: "1px solid #ccc",
                                            maxWidth: "300px", // Adjust the maximum width as needed
                                        }}
                                        value={enrichmentRequest.query}
                                        placeholder="Search by Name, Phone and Email"
                                        name="query"
                                        onChange={(e) => setEnrichmentRequest({ query: e.target.value })}
                                    />
                                    <button
                                        className="btn btn-primary ms-2"
                                        onClick={handleSubmit}
                                    >
                                        Search
                                    </button>
                                    <div className="fv-row  py-2" >
                                    </div>
                                </div>
                                <div className="invalid-feedback px-2"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-5 mt-5 g-xxl-8">
                        {/*begin::Col*/}
                        <div className="col-xl-12">
                            {/*begin::Feeds Widget 1*/}

                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-title w-100">
                                            <h3 className='me-auto pb-2'>Leads</h3>
                                                <div className="d-flex align-items-center position-relative my-1">
                                                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                                                        <span className="path1"></span>
                                                        <span className="path2"></span>
                                                    </i>
                                                    <input type="text" data-kt-user-table-filter="search" value={filter.search_string} onChange={(e) => setFilter({ ...filter, search_string: e.target.value })} className="form-control form-control-solid w-250px ps-13 border" placeholder="Search Lead" />
                                                </div>
                                            </div>
                                        </div>
                                        {/**end::Card header**/}
                                        {/**begin::Card body**/}
                                        <div className="card-body table-frame py-4">
                                            <div className='table-responsive'>
                                                <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_leads">
                                                    <thead>
                                                        <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">

                                                            <th className="min-w-140px">Customer Name</th>
                                                            <th className="min-w-140px">Customer email</th>
                                                            <th className="min-w-140px">Customer phone</th>
                                                            <th className="min-w-140px">Created date</th>
                                                            <th className="min-w-125px text-center">Status</th>
                                                            <th className="min-w-125px text-center">Action</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody className="text-gray-600 fw-semibold">
                                                        {leads.length > 0 ? renderTabeBody() : <tr>
                                                            <td colSpan={6} className='text-center'>No result found!</td>
                                                        </tr>}
                                                    </tbody>
                                                </table>

                                                <Pagination className="pagination-bar" currentPage={paginationData.page} totalCount={paginationData.totalCount}
                                                    pageSize={paginationData.perpage} onPageChange={page => getUserLeads(page, filter.status)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/**end::Card**/}
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
}
