import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { callCommonAction } from "../../../redux/Common/CommonReducer";
import { sendRequest } from "../../../apis/APIs";
import { handleApiResponse } from "../../../apis/apiUtils";
import appSettings from "../../../configs/AppConfig";
import { Helmet } from "react-helmet";
import Form from "react-bootstrap/Form";
import appRoutes from "../../../configs/AppRoutes";
import Alerts from "../../../common/Alerts/Alerts";
import moment from "moment";
import Pagination from "../../../common/Pagination/pagination";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { alert, user, loading2 } = useSelector((state) => state.common);
  const [paginationData, setPaginationData] = useState({ perpage: 6, page: 1, totalCount: 0, });
  const [pendingEnrichReq, setPendingEnrichReq] = useState([]);
  const [dashboard, setDashboard] = useState({ credits: 0, totalAmount: 0, number_of_dnc_req: 0, number_of_full_contact_req: 0 });
  const [filter, setFilter] = useState({ search_string: "" });
  const [creditEnable, setCreditEnable] = useState(false);

  useEffect(() => {
    getDashboardData();
  }, []);

  useEffect(() => {
    if (filter.search_string.length > 2 || filter.search_string === "") {
      getPendingEnrichReq(1);
    }
  }, [filter.search_string]);

  /** Get Dashboard Data **/
  const getDashboardData = async () => {
    try {
      dispatch(callCommonAction({ loading: true, breadCrumb: ['Dashboards', 'Default'] }));
      const res = await sendRequest(`/user/dashboard`, 'GET');
      dispatch(callCommonAction({ loading: false }));
      handleApiResponse(res);
      if (res.data.type === "success") {
        const { userData, totalAmount } = res.data.data;
        setCreditEnable(userData.credit_enable);
        setDashboard({ credits: userData.credits, totalAmount: totalAmount, number_of_dnc_req: userData.number_of_crm_req, number_of_full_contact_req: userData.number_of_full_contact_req });
      }
    } catch (error) {
      toast.error('Something went wrong');
      dispatch(callCommonAction({ loading: false }));
    }
  };

  const getPendingEnrichReq = async (page) => {
    try {
      dispatch(callCommonAction({ loading2: true, breadCrumb: ["Dashbord", "List"] }));
      const res = await sendRequest(`/enrich/request-list`, "GET", { page: page, limit: paginationData.perpage, search_string: filter.search_string, status: 0 });
      dispatch(callCommonAction({ loading2: false }));
      handleApiResponse(res);
      if (res.data.type === "success") {
        setPendingEnrichReq(res.data.data.docs);
        setPaginationData({ ...paginationData, totalCount: res.data.data.totalDocs, page: res.data.data.page, });
      }
    } catch (error) {
      toast.error("Something went wrong");
      dispatch(callCommonAction({ loading2: false }));
    }
  };




  const renderTabeBody = () => {
    return pendingEnrichReq.map((penEnriReq) => {
      return (
        <tr key={penEnriReq.id}>
          <td className="min-w-125px"> <span>{penEnriReq.firstName + ' ' + penEnriReq.lastName}</span></td>
          <td className="min-w-125px"> <span>{penEnriReq.email}</span></td>
          <td className="min-w-125px"> <span>{penEnriReq.phone}</span></td>
          <td className="min-w-125px">{moment(new Date(penEnriReq.created_at)).format('MM/DD/YYYY')}</td>
          <td className="min-w-125px"> <span className="badge badge-light-danger">{penEnriReq.status_name}</span></td>
          {/* <td className="text-end">
            <span className="btn btn-primary btn-sm">
              <i className="ki-duotone text-red ki-arrow-up-refraction fs-3">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </span>
          </td> */}
        </tr>
      );
    });
  };

  const callCommonDispatch = () => {
    dispatch(callCommonAction({ isAsideOpen: false }));
  };

  return (
    <>
      {console.log('as', creditEnable)}
      {/*begin::Content*/}
      {alert}
      <div className="content d-flex flex-column flex-column-fluid col-md-12" id="kt_content"  >
        <Helmet>
          <title>leadBoost | Dashboard</title>
          <meta name="description" content="Description goes here" />
          <meta name="keywords" content="Game, Entertainment, Movies" />
        </Helmet>
      </div>
      <div className="post d-flex flex-column-fluid" id="kt_post">
        {/**begin::Container**/}

        {/**end::Container**/}

        {/* new container start here */}
        <div id="kt_content_container" className="container-xxl">
          <div className="row g-5 g-xl-10">
            <div className="col-xl-4">
              {/**begin::Lists Widget 19**/}
              <div className="card card-flush h-xl-100">
                {/**begin::Heading**/}
                <div className="stats-head card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px">
                  {/**begin::Title**/}
                  <h3 className="card-title align-items-start flex-column justify-content-center text-white pt-15 w-100">
                    <span className="fw-bold fs-2x mb-3 text-center w-100">
                      Account Snapshot
                    </span>
                  </h3>
                  {/**end::Title**/}
                  {/**begin::Toolbar**/}

                  {/**end::Toolbar**/}
                </div>
                {/**end::Heading**/}
                {/**begin::Body**/}
                <div className="card-body mt-n20">
                  {/**begin::Stats**/}
                  <div className="mt-n20 position-relative">
                    {/**begin::Row**/}
                    <div className="row g-3 g-lg-6">
                      {/**begin::Col**/}
                      <div
                        className="col-6"

                      >
                        {/**begin::Items**/}
                        <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5 h-100">
                          {/**begin::Symbol**/}
                          <div className="symbol symbol-30px me-5 mb-8">
                            <span className="symbol-label">
                              <i className="ki-duotone ki-profile-user fs-1 text-primary">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                                <span className="path4"></span>
                              </i>
                            </span>
                          </div>
                          {/**end::Symbol**/}
                          {/**begin::Stats**/}
                          <div className="m-0">
                            <span className="text-gray-700 fw-bolder d-block fs-2 lh-1 ls-n1 mb-1">
                              {dashboard.number_of_full_contact_req}
                            </span>

                            {/**begin::Number**/}
                            {/* <span className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">{dashboard.userCount}</span> */}
                            {/**end::Number**/}
                            {/**begin::Desc**/}
                            <span className="text-gray-500 fw-semibold fs-6">
                              No. of Enrichment Requests
                            </span>
                            {/**end::Desc**/}
                          </div>
                          {/**end::Stats**/}
                        </div>
                        {/**end::Items**/}
                      </div>
                      {/**end::Col**/}
                      {/**begin::Col**/}
                      {/* <div className="col-6 cursor-pointer" onClick={() => navigate(appRoutes.adminOfferListRoute)}> */}
                      <div className="col-6">
                        {/**begin::Items**/}
                        <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5 h-100">
                          {/**begin::Symbol**/}
                          <div className="symbol symbol-30px me-5 mb-8">
                            <span className="symbol-label">
                              <i className="ki-duotone ki-discount text-primary fs-1">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </span>
                          </div>
                          {/**end::Symbol**/}
                          {/**begin::Stats**/}
                          <div className="m-0">
                            {/**begin::Number**/}
                            <span className="text-gray-700 fw-bolder d-block fs-2 lh-1 ls-n1 mb-1">
                              {`$${parseFloat(dashboard.totalAmount).toFixed(2)}`}
                            </span>
                            {/**end::Number**/}
                            {/**begin::Desc**/}
                            <span className="text-gray-500 fw-semibold fs-6">
                              Total amount spent
                            </span>
                            {/**end::Desc**/}
                          </div>
                          {/**end::Stats**/}
                        </div>
                        {/**end::Items**/}
                      </div>
                      {/**end::Col**/}
                      {/**begin::Col**/}
                      {/* <div className="col-6 cursor-pointer" onClick={() => navigate(appRoutes.adminNewsListRoute)}> */}
                      <div className="col-6 ">
                        {/**begin::Items**/}
                        <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5 h-100">
                          {/**begin::Symbol**/}
                          <div className="symbol symbol-30px me-5 mb-8">
                            <span className="symbol-label">
                              <i className="ki-duotone ki-award fs-1 text-primary">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                              </i>
                            </span>
                          </div>
                          {/**end::Symbol**/}
                          {/**begin::Stats**/}
                          <div className="m-0">
                            {/**begin::Number**/}
                            <span className="text-gray-700 fw-bolder d-block fs-2 lh-1 ls-n1 mb-1">
                              {dashboard.number_of_dnc_req}
                            </span>
                            {/**end::Number**/}
                            {/**begin::Desc**/}
                            <span className="text-gray-500 fw-semibold fs-6">
                              No. of  DNC request
                            </span>
                            {/**end::Desc**/}
                          </div>
                          {/**end::Stats**/}
                        </div>
                        {/**end::Items**/}
                      </div>
                      {/**end::Col**/}

                      {/**begin::Col**/}
                      {/* <div className="col-6 cursor-pointer" onClick={() => navigate(appRoutes.adminNewsListRoute)}> */}
                      <div className="col-6">
                        {/**begin::Items**/}
                        <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5 h-100">
                          {/**begin::Symbol**/}
                          <div className="symbol symbol-30px me-5 mb-8">
                            <span className="symbol-label">
                              <i className="ki-duotone ki-award fs-1 text-primary">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                              </i>
                            </span>
                          </div>
                          {/**end::Symbol**/}
                          {/**begin::Stats**/}
                          <div className="m-0">
                            {/**begin::Number**/}
                            <span className="text-gray-700 fw-bolder d-block fs-2 lh-1 ls-n1 mb-1">
                              {dashboard.credits}
                            </span>
                            {/**end::Number**/}
                            {/**begin::Desc**/}
                            <span className="text-gray-500 fw-semibold fs-6">
                              Credit Balance
                            </span>
                            {/**end::Desc**/}
                          </div>
                          {/**end::Stats**/}
                        </div>
                        {/**end::Items**/}
                      </div>
                      {/**end::Col**/}
                      <div className="text-center">
                        <button className="btn btn-primary btn-sm " onClick={() => { callCommonDispatch(); navigate(appRoutes.purchaseCreditRoute); }}> Buy More Credits</button>
                      </div>

                    </div>
                    {/**end::Row**/}
                  </div>
                  {/**end::Stats**/}
                </div>
                {/**end::Body**/}
              </div>
              {/**end::Lists Widget 19**/}
            </div>

            <div className="col-xl-8">

              <div className="card">
                {/**begin::Card header**/}
                <div className="card-header border-0 px-4">
                  {/**begin::Card title**/}
                  <div className="card-title">
                    <h4>Pending Request</h4>
                  </div>
                  {/**begin::Card title**/}
                  {/**begin::Card toolbar**/}
                  <div className="card-toolbar">
                    {/**end::Toolbar**/} {/**begin::Search**/}
                    <div className="d-flex align-items-center position-relative my-1">
                      <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      <input
                        type="text"
                        data-kt-user-table-filter="search"
                        value={filter.search_string}
                        onChange={(e) =>
                          setFilter({ ...filter, search_string: e.target.value })
                        }
                        className="form-control form-control-solid w-250px ps-13 border"
                        placeholder="Search request..."
                      />
                    </div>
                    {/**end::Search**/}
                  </div>
                  {/**end::Card toolbar**/}
                </div>
                {/**end::Card header**/}
                {/**begin::Card body**/}
                <div className="card-body table-frame p-4 border-top ">
                  {/**begin::Table**/}
                  <div className="table-responsive">
                    <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_users">
                      <thead>
                        <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                          <th className="min-w-125px">Name</th>
                          <th className="min-w-125px">Email</th>
                          <th className="min-w-125px">Phone</th>
                          <th className="min-w-125px">Request Date</th>
                          <th className="min-w-125px">Status</th>
                          {/* <th className="text-end ">Action</th> */}
                        </tr>
                      </thead>
                      <tbody className="text-gray-600 fw-semibold">
                        {pendingEnrichReq.length > 0 ? renderTabeBody() : <tr>
                          <td colSpan={6} className='text-center'>No pending request found!</td>
                        </tr>}
                      </tbody>
                    </table>
                  </div>
                  {/**end::Table**/}
                  <Pagination
                    className="pagination-bar"
                    currentPage={paginationData.page}
                    totalCount={paginationData.totalCount}
                    pageSize={paginationData.perpage}
                    onPageChange={(page) => getPendingEnrichReq(page)}
                  />
                </div>
                {/**end::Card body**/}
              </div>

              {/**end::Table**/}
            </div>

          </div>
        </div>
      </div>

      {/*end::Content*/}
    </>
  );
};

export default Dashboard;
