import React, { useEffect, useState } from "react";
import { sendRequest } from "../../../apis/APIs";
import { Helmet } from "react-helmet";
import { callCommonAction } from "../../../redux/Common/CommonReducer";
import { useDispatch } from "react-redux";
import { validateCreditFormData } from './Validation';
import { handleApiResponse } from '../../../apis/apiUtils';

const Credit = () => {
	const dispatch = useDispatch();
	const [errorsInfo, setErrorsInfo] = useState({});
	const [creditSettingData, setCreditSettingData] = useState({ credit_per_request: 0, amount_per_credit: 0, default_purchase_credit: 0, mimimum_threshold_credits: 0 });

	/** Use Effect Call To Get Credit Data */
	useEffect(() => { getCreditData(); }, []);

	const getCreditData = async () => {
		try {
			const res = await sendRequest("/admin/get-credit", "GET");
			setCreditSettingData(res.data.data);
		} catch (error) {
			console.error("Something went wrong", error);
		}
	};

	/** Handle Input Change Manage Heere */
	const handleChangeInput = (e) => {
		const { name, value } = e.target;
		creditSettingData[name] = parseFloat(value);
		setCreditSettingData({ ...creditSettingData, [name]: parseFloat(value) });
		checkFormIsValid(e.target.name);
	};


	/* Validation implemented here */
	const checkFormIsValid = (fieldName) => {
		const res = validateCreditFormData(creditSettingData, fieldName, errorsInfo);
		setErrorsInfo(res.errors);
		return res.formValidCheck;
	};

	const handleApiFormSubmit = async (e) => {
		e.preventDefault();
		if (checkFormIsValid('')) {
			dispatch(callCommonAction({ loading: true }));
			try {
				const res = await sendRequest("/admin/update-credit", "POST", creditSettingData);
				handleApiResponse(res);
				dispatch(callCommonAction({ loading: false }));
			} catch (error) {
				console.error("Something went wrong", error);
			}
		}
	};


	return (
		<div
			className="content d-flex flex-column flex-column-fluid"
			id="kt_content"
		>
			<Helmet>
				<title>Lead Boost | Carrier List </title>
				<meta name="description" content="Description goes here" />
				<meta name="keywords" content="Game, Entertainment, Movies" />
			</Helmet>
			{/**begin::Post**/}
			<div className="post d-flex flex-column-fluid" id="kt_post">
				{/**begin::Container**/}
				<div id="kt_content_container" className="container-xxl">
					{/**begin::Card**/}
					<div className="card">
						{/**begin::Card header**/}
						<div className="card-header">
							{/**begin::Card title**/}
							<div className="card-title">
								<h4>Credits Management</h4>
							</div>
						</div>
						{/**end::Card header**/}
						{/**begin::Card body**/}
						<div className="card-body">
							<form onSubmit={handleApiFormSubmit}>
								<div className="row">
									<div className="col-md-8">
										<div className="row">
											{/* <div className="col">
												<div className="fv-row mb-7 fv-plugins-icon-container">
													<label className="fs-6 fw-semibold form-label mt-3">
														<span className="required">Credit per request</span>
													</label>
													<input
														type="number"
														placeholder=""
														onChange={(e) => {
															handleChangeInput(e);
														}}
														value={creditSettingData.credit_per_request}
														className={`form-control ${errorsInfo.credit_per_request ? "is-invalid" : ""
															}`}
														name="credit_per_request"
													/>
													<div className="fv-plugins-message-container">
														<div className="fv-help-block">
															<span role="alert">{errorsInfo.credit_per_request}</span>
														</div>
													</div>
												</div>
											</div> */}

											<div className="col-12 col-md-6">
												<div className="fv-row mb-7 fv-plugins-icon-container">
													<label className="fs-6 fw-semibold form-label mt-3">
														<span className="required">Amount per credit</span>
													</label>
													<input
														type="number"
														onChange={(e) => {
															handleChangeInput(e);
														}}
														value={creditSettingData.amount_per_credit}
														className={`form-control  ${errorsInfo.amount_per_credit ? "is-invalid" : ""
															}`}
														name="amount_per_credit"
													/>
													<div className="fv-plugins-message-container">
														<div className="fv-help-block">
															<span role="alert">{errorsInfo.amount_per_credit}</span>
														</div>
													</div>
												</div>
											</div>
											<div className="col-12 col-md-6">
												<div className="fv-row mb-7 fv-plugins-icon-container">
													<label className="fs-6 fw-semibold form-label mt-3">
														<span className="required">Credit per request for FullContact</span>
													</label>
													<input
														type="number"
														placeholder=""
														onChange={(e) => {
															handleChangeInput(e);
														}}
														value={creditSettingData?.credit_per_request_full_contact}
														className={`form-control ${errorsInfo.credit_per_request_full_contact ? "is-invalid" : ""
															}`}
														name="credit_per_request_full_contact"
													/>
													<div className="fv-plugins-message-container">
														<div className="fv-help-block">
															<span role="alert">{errorsInfo.credit_per_request_full_contact}</span>
														</div>
													</div>
												</div>
											</div>
										</div>
										
										<div className="row">

											<div className="col-12 col-md-6">
												<div className="fv-row mb-7 fv-plugins-icon-container">
													<label className="fs-6 fw-semibold form-label mt-3">
														<span className="required">Credit per request for DNC</span>
													</label>
													<input
														type="number"
														placeholder=""
														onChange={(e) => {
															handleChangeInput(e);
														}}
														value={creditSettingData?.credit_per_request_dnc}
														className={`form-control ${errorsInfo.credit_per_request_dnc ? "is-invalid" : ""
															}`}
														name="credit_per_request_dnc"
													/>
													<div className="fv-plugins-message-container">
														<div className="fv-help-block">
															<span role="alert">{errorsInfo.credit_per_request_dnc}</span>
														</div>
													</div>
												</div>
											</div>
											<div className="col-12 col-md-6">
												<div className="fv-row mb-7 fv-plugins-icon-container">
													<label className="fs-6 fw-semibold form-label mt-3">
														<span className="required">Credit per request for Driver record</span>
													</label>
													<input
														type="number"
														placeholder=""
														onChange={(e) => {
															handleChangeInput(e);
														}}
														value={creditSettingData?.credit_per_request_driver_record}
														className={`form-control ${errorsInfo.credit_per_request_driver_record ? "is-invalid" : ""
															}`}
														name="credit_per_request_driver_record"
													/>
													<div className="fv-plugins-message-container">
														<div className="fv-help-block">
															<span role="alert">{errorsInfo.credit_per_request_driver_record}</span>
														</div>
													</div>
												</div>
											</div>
											
										</div>
										<div className="row">

											<div className="col-12 col-md-6">
												<div className="fv-row mb-7 fv-plugins-icon-container">
													<label className="fs-6 fw-semibold form-label mt-3">
														<span className="required">Credit per request for Insurance</span>
													</label>
													<input
														type="number"
														placeholder=""
														onChange={(e) => {
															handleChangeInput(e);
														}}
														value={creditSettingData?.credit_per_request_insurance}
														className={`form-control ${errorsInfo.credit_per_request_insurance ? "is-invalid" : ""
															}`}
														name="credit_per_request_insurance"
													/>
													<div className="fv-plugins-message-container">
														<div className="fv-help-block">
															<span role="alert">{errorsInfo.credit_per_request_insurance}</span>
														</div>
													</div>
												</div>
											</div>
											<div className="col-12 col-md-6">
												<div className="fv-row mb-7 fv-plugins-icon-container">
													<label className="fs-6 fw-semibold form-label mt-3">
														<span className="required">Default purchase credit limit</span>
													</label>
													<input
														type="number"
														onChange={(e) => {
															handleChangeInput(e);
														}}
														value={creditSettingData.default_purchase_credit}
														className={`form-control ${errorsInfo.default_purchase_credit ? "is-invalid" : ""
															}`}
														name="default_purchase_credit"
													/>
													<div className="fv-plugins-message-container">
														<div className="fv-help-block">
															<span role="alert">{errorsInfo.default_purchase_credit}</span>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="row">

											<div className="col-12 col-md-6">
												<div className="fv-row mb-7 fv-plugins-icon-container">
													<label className="fs-6 fw-semibold form-label mt-3">
														<span className="required">Minimum threshold of credits</span>
													</label>
													<input
														type="number"
														onChange={(e) => {
															handleChangeInput(e);
														}}
														value={creditSettingData.mimimum_threshold_credits}
														className={`form-control ${errorsInfo.mimimum_threshold_credits ? "is-invalid" : ""
															}`}
														name="mimimum_threshold_credits"
													/>

													<div className="fv-plugins-message-container">
														<div className="fv-help-block">
															<span role="alert">{errorsInfo.mimimum_threshold_credits}</span>
														</div>
													</div>
												</div>
											</div>

											<div className="col-12 col-md-6">
												<div className="fv-row mb-7 fv-plugins-icon-container">
													<label className="fs-6 fw-semibold form-label mt-3">
														<span className="required">Credit per request for Voucher</span>
													</label>
													<input
														type="number"
														onChange={(e) => {
															handleChangeInput(e);
														}}
														value={creditSettingData.credit_per_request_voucher}
														className={`form-control ${errorsInfo.credit_per_request_voucher ? "is-invalid" : ""
															}`}
														name="credit_per_request_voucher"
													/>

													<div className="fv-plugins-message-container">
														<div className="fv-help-block">
															<span role="alert">{errorsInfo.credit_per_request_voucher}</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									{/* <div className="col-md-4 d-flex align-items-center justify-content-center mb-2">
										<div className="row w-m-100">
											<div className="card card-xl-stretch p-5 theme-dark-bg-body" style={{ backgroundColor: '#25aae1' }}>
												
												<div className="card-body d-flex flex-column">
													
													<div className="d-flex flex-column flex-grow-1">
													
														<a href={undefined} className="text-white text-hover-primary fw-bold fs-3">Calculation</a>
														
													</div>
													<div className="pt-5">
														
														<span className="text-white fw-bold fs-2x lh-0">$</span>
														

														
														<span className="text-white fw-bold fs-3x me-2 lh-0">{(creditSettingData?.amount_per_credit) * (creditSettingData?.credit_per_request)}</span>
														

													
														<span className="text-white fw-bold fs-6 lh-0">for single request hit</span>
														
													</div>
												
												</div>
											
											</div>
										</div>

									</div> */}
								</div>



								<div className="row row-cols-1 row-cols-sm-3">
									<div className="col-12 col-md-6 align-items-start d-flex flex-column">
										<button type="submit" className="btn btn-primary">
											Save
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Credit;
