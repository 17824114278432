import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callCommonAction } from "../../../redux/Common/CommonReducer";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { sendRequest } from "../../../apis/APIs";
import { loadStripe } from "@stripe/stripe-js";
import { handleApiResponse } from "../../../apis/apiUtils";
import appSettings from "../../../configs/AppConfig";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from '../../../common/Pagination/pagination';
import moment from 'moment';
import Dropdown from "react-bootstrap/Dropdown";
import appRoutes from "../../../configs/AppRoutes";

export default function Detail() {
    const [leads, setLeads] = useState({});
    const params = useParams();
    const [filter, setFilter] = useState({ search_string: "", status: "all" });
    const [paginationData, setPaginationData] = useState({ perpage: appSettings.recordsPerPageTable, page: 1, totalCount: 0 });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (filter.search_string.length > 2 || filter.search_string === "") {
            getUserLeads(1, filter.status);
        }
    }, [filter.search_string]);


    const getUserLeads = async (page, status) => {
        try {
            dispatch(callCommonAction({ breadCrumb: ['Enrichment', 'Detail'] }));
            let body = { page: page, limit: paginationData.perpage, search_string: filter.search_string, status: status, };
            if (params.id !== undefined) {
                body.enrich_request = params.id;
            }
            const res = await sendRequest(`/lead/list`, 'GET', body);
           
            handleApiResponse(res);

            if (res.data.type === "success") {
                toast.success('Here is your search results');
                setLeads(res.data.data.docs);
                setPaginationData({ ...paginationData, totalCount: res.data.data.totalDocs, page: res.data.data.page });
            }
        } catch (error) {
            toast.error('Something went wrong');
           
        }
    };


    const renderTabeBody = () => {
        return leads.map((lead) => {
            let badgeCls = lead.status === 1 ? 'success' : 'danger';
            return (
                <tr key={lead.id}>
                    <td className="min-w-125px"> <span>{lead.contactName}</span></td>
                    <td className="min-w-125px"> <span>{lead.email}</span></td>
                    <td className="min-w-125px"> <span>{lead.phone}</span></td>
                    <td className="min-w-125px">{moment(new Date(lead.created_at)).format('MM/DD/YYYY')}</td>
                    <td className="min-w-125px text-center"> <span className={`text-uppercase badge badge-light-${badgeCls}`}>{lead.status_name}</span></td>
                    <td className="text-end">
                        <button type="button" className="btn btn-primary btn-sm" onClick={() =>  navigate(appRoutes.leadDetail + "/" + lead._id) } >View</button>
                    </td>
                </tr>
            );
        });
    };

    return (
        <>
            <div className="post d-flex flex-column-fluid" id="kt_post">
                <div id="kt_content_container" className="container-xxl">

                    <div className="row g-5 mt-5 g-xxl-8">
                        {/*begin::Col*/}
                        <div className="col-xl-12">
                            {/*begin::Feeds Widget 1*/}
                            <div className="card mb-5 mb-xxl-8">
                                {/*begin::Body*/}
                                <div className="card-body table-frame">
                                    <h3 className='pb-2'>Leads</h3>

                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-title">
                                                <div className="d-flex align-items-center position-relative my-1">
                                                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                                                        <span className="path1"></span>
                                                        <span className="path2"></span>
                                                    </i>
                                                    <input type="text" data-kt-user-table-filter="search" value={filter.search_string} onChange={(e) => setFilter({ ...filter, search_string: e.target.value })} className="form-control form-control-solid w-250px ps-13 border" placeholder="Search Lead" />
                                                </div>
                                            </div>
                                            {/**begin::Card toolbar**/}
                                            <div className="card-toolbar">
                                                <span className="btn btn-primary btn-sm" onClick={() => navigate(appRoutes.enrichmentRoute)}><i className="ki-duotone ki-left"></i>Back</span>
                                            </div>
                                            {/**end::Card toolbar**/}

                                        </div>
                                        {/**end::Card header**/}
                                        {/**begin::Card body**/}
                                        <div className="card-body table-frame py-4">
                                            <div className='table-responsive'>
                                                <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_leads">
                                                    <thead>
                                                        <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">

                                                            <th className="min-w-140px">Customer Name</th>
                                                            <th className="min-w-140px">Customer email</th>
                                                            <th className="min-w-140px">Customer phone</th>
                                                            <th className="min-w-140px">Created date</th>
                                                            <th className="min-w-125px text-center">Status</th>
                                                            <th className="min-w-125px text-end">Action</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody className="text-gray-600 fw-semibold">
                                                        {leads.length > 0 ? renderTabeBody() : <tr>
                                                            <td colSpan={6} className='text-center'>No result found!</td>
                                                        </tr>}
                                                    </tbody>
                                                </table>

                                                <Pagination className="pagination-bar" currentPage={paginationData.page} totalCount={paginationData.totalCount}
                                                    pageSize={paginationData.perpage} onPageChange={page => getUserLeads(page, filter.status)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/**end::Card**/}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
}
